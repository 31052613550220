.pageloading {
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: fixed;
    left: 0;
    top: 0;
    @extend %setFlex-center;
    flex-direction: column;
    justify-content: center;
    transition: 0.2s 0.2s;
    z-index:10000;
    .loading {
        width: 200px;
        height: 8px;
        background-color: #fff;
        margin-top: 10px;
        border-radius: 5px;
        overflow: hidden;
        .bar {
            width: 0%;
            height: 100%;
            background-color: $colorMain;
        }
    }
    &.compelet {
        z-index:-1;
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes jumping {
    50% {
        top: 0;
    }
    100% {
        top: -50px;
    }
}

@keyframes eyemove {
    0%, 10% {
        transform: translateX(50%);
    }
    90%, 100% {
        transform: translateX(-50%);
    }
}

// 

.letter {
    float: left;
    @include fontSize(18px);
    color: $colorBlack;
}

.letter-holder .letter {
    animation-name: loadingF;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: linear;
}

.l{
    &-1 {
        animation-delay: 0.48s;
    }
    &-2 {
        animation-delay: 0.53s;
    }
    &-3 {
        animation-delay: 0.58s;
    }
    &-4 {
        animation-delay: 0.63s;
    }
    &-5 {
        animation-delay: 0.69s;
    }
    &-6 {
        animation-delay: 0.74s;
    }
    &-7 {
        animation-delay: 0.79s;
    }
    &-8 {
        animation-delay: 0.84s;
    }
    &-9 {
        animation-delay: 0.89s;
    }
    &-10 {
        animation-delay: 0.94s;
    }
}
 
@keyframes loadingF {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

// <div class="pageloading">
//     <div class="monster">
//       <div class="img">
//         <img alt="" src="assets/images/loading-logo.svg" width="146" height="126"/>
//       </div>
//     </div>
  
//     <div class="letter-holder">
//       <div class="l-1 letter">頁</div>
//       <div class="l-2 letter">面</div>
//       <div class="l-3 letter">載</div>
//       <div class="l-4 letter">入</div>
//       <div class="l-5 letter">中</div>
//       <div class="l-6 letter">，</div>
//       <div class="l-7 letter">請</div>
//       <div class="l-8 letter">稍</div>
//       <div class="l-9 letter">後</div>
//       <div class="l-10 letter">!</div>
//     </div>
  
//     <div class="loading d-none">
//         <div class="bar"></div>
//     </div>
// </div>

.js-loadHide{
    display: none !important;
}

.loader{
    margin: 0 0 2em;
    height: 100px;
    width: 20%;
    text-align: center;
    padding: 1em;
    margin: 0 auto 1em;
    display: inline-block;
    vertical-align: top;
}
  
  /* Set the color of the icon */
svg{
    path,
    rect{
        fill: $colorMain;
    }
}