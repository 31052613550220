.dowload{
    &-fileName,
    &-act{
        text-align: left;
    }

    &-num{
        padding-right: 5px;
        @include setMinRwd{
            flex: 0 0 65px;
        }
    }

    &-fileName{
        flex: 1;
        padding-left: 1rem;
    }
    
    &-act{
        .btn{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            @include fontSize(18px);
            padding-left: 1em;
            padding-right: 1em;
            border-color: #a0a0a0;
            &::after{
                content: '';
                display: block;
                @include setSize(23px,23px);
                background-image: url('../images/icon-download-gray.png');
            }
            &:hover{
                background-color: $colorMain;
                border-color: $colorMain;
                color: #fff;
                &::after{
                    background-image: url('../images/icon-download-w.png');
                }
            }
        }

        @include setMinRwd{
            flex: 0 0 300px;
        }
    }

    // 框
    &-wrap{
        width: 100%;
        margin-bottom: 1rem;
        p{
            margin-bottom: 0;
        }
        
        .btn{
            display: block;
            width: 100%;
            text-align: left;
            padding:0.5rem 0.75rem;
            padding-right: 2rem;
            color:#8c8b8b;
            border-color: #8c8b8b;
            @include setBackSet(calc(100% - 10px),auto);
            background-image: url('../images/icon-download-g.png');
            @include fontSize(16px);
            white-space:nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            &,
            &:hover{
                background-color: #fff;
            }
            &:hover{
                color:#000;
                border-color: $colorMain;
                background-image: url('../images/icon-download-b.png');
            }
        }
    }

    &-item,
    &-list{
        padding-bottom: 20px;
        text-align: center;
    }

    &-item{
        @include fontSize(18px);
        color:#696969;
        border-bottom: 1px solid #434343;
        @extend %setFlex;
        justify-content: space-between;
        padding-top: 10px;
        @include mobile{
            display: none;
        }
    }

    &-list{
        @include fontSize(20px);
        color:#646464;
        line-height: 1.5;
        li{  
            @extend %setFlex-center;
            justify-content: space-between;
            border-bottom: 2px solid #e1e1e1;
            padding-top: 1rem;
            padding-bottom: 1rem;
            .dowload-num{
                color: #747474;
            }
        }
        
        @include mobile{
            padding-left: 5px;
            padding-right: 5px;
            li{
                padding-top: 1rem;
                padding-bottom: 1rem;
                .dowload-fileName{    
                    padding-left: 0;
                }
                &>div{
                    width: 100%;
                    margin-top: 1rem;
                    margin-bottom: 0.5rem;
                    // display: flex;
                    // align-items: center;
                    // &::before{
                    //     content: attr(data-title);
                    //     display: inline-block;
                    //     @include fontSize(14px);
                    //     font-weight: 100;
                    //     line-height: 1.3;
                    //     margin: 2.5px;
                    //     padding: 10px;
                    //     text-align: center;
                    //     color: white;
                    //     background: lighten($colorMain, $amount: 10);
                    //     flex: 0 0 100px;
                    //     margin-right: 10px;
                    // }
                }
            }
        }
    }
}