footer {
  color: #fff;

  .wrapper{
    @extend %setFlex;
    align-items: flex-start;
  }

  .footer {
    &-top,
    &-bottom{
      display: flex;
    }

    &-top{
      background-color: $colorFooter;
      padding-top: 20px;
      padding-bottom: 15px;

      @include setWidthRwd(1101px,1600px){
        padding-left: 1em;
        padding-right: 1em;
        padding-bottom: 1em;
      }

      @include setMaxRwd{
        padding: 1em 0.25em;
      }
    }

    &-bottom{
      justify-content: flex-end;

      p{
        margin-bottom: 0;
      }

      @include fontSize(14px);
      font-weight: 100;
      line-height: 1.5;

      @include setMaxRwd{
        @include fontSize(12px);
        justify-content: center;
        text-align: center;
        &-right{
          display: block;
        }
      }
    }

    &-info{
      font-size: 0.875em;
      font-weight: 100;
      padding-left: 10px;
      padding-top: 7px;
      p{
        margin-bottom: 0.5em;
      }

      // 
      &-left{
        display: flex;
        align-items: flex-start;
        @include setMinRwd{
          align-items: center;
        }
      }

      @include setMaxRwd{
        padding-top: 10px;
      }
    }

    // 項目
    &-title{
      margin-bottom: 0.25em;
      font-weight: 900;

      @include setMaxRwd{
        font-size: 2em;
      }
    }

    &-txt{
      @include fontSize(17px);
      &-list{
        @include setMinRwd{
          padding-right: 37.5px;
        }

        @include mobile{
          display: block;
          position: relative;
          padding-left: 1.5em;
          .icon{
            position: absolute;
            left: 0;
            top: 4px
          }
        }
      }
    }

    // 社群
    &-icon{
      @extend %setFlex-center;
      justify-content: flex-end;
      margin-top: 0.5em;
      &Link{
        margin-right: 5px;
      }
    }

    // grid
    &-logo {
      display: flex;
      align-items: center;
      img {
          display: block;
          max-width: 100%;
      }
    }

    &-info-wrap{
      @extend %setFlex-center;
      width: 100%;
      li{
        flex: auto;
        @include setMinRwd{
          margin: 0 1em;
        }
      }

      @include setMaxRwd{
        flex: auto;
        width: 100%;
        padding-left: 0.5em;
        padding-right: 0.5em;
        li{
          &+li{
            margin-top: 0.5em;
          }
          flex: auto;
          width: 100%;
        }
      }
    }

    &-info-bottom{
      @include setMaxRwd{
        margin-top: 1.5em !important;
        .footer-icon,
        .footer-info,
        .copyright p{
          justify-content: center;
        }
        .copyright{
          flex-direction: column;
        }
      }
    }
  }

  @include setMaxRwd{
    margin-bottom: 2.25em;
  }
}

// 給予區塊的左右側線條使用
.divider {
  width: 2px;
  height: 100%;
  background: #bfb1a5;
  @include setWidthRwd(800px,1000px){
    display: none;
  }
}

.iware {
  font-size: 87%;
  font-weight: 100;
  font-weight: lighter;
  &,
  a{
    color:#fff;
  }

  a {
      &:hover {
          text-decoration: underline;
      }
  }

  b{
    font-weight: 100;
  }
}

// Gotop 使用
.float-link {
  right: -100%;
  position: fixed;
  bottom: 200px;
  z-index: 10;
  transition: 0.3s;
  .link-btn {
    a {
      &:hover {
          opacity: 0.65;
      }
    }

    &.nabar-icon-mobile{
      @include setMinRwd{
        display: none;
      }
      img{
        max-width: 100%;
      }
    }

    &.top{
      @include setMaxRwd{
        display: none;
      }
    }
  }

  &.active {
      right: 5px;
  }

  @include setStyleRwd(1600px){
    &.active {
        right: 10px;
    }
  }

  @include setMaxRwd{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    bottom: 0;
    right: 0 !important;
    background: $colorMain;
    padding: 5px;
    margin: 0;
    .link-btn {
      text-align: center;
      margin: 0;

      &,
      .link-btn-toggle{
        display: block;
        width: 100%;
      }

      .link-btn-toggle{
        color: #fff;
      }

      .icon{
        small{
          font-size: 100%;
        }
      }
    }
  }
}

// Cookie Privacy
.cookies {
  display: none;
  width: 100%;
  background: #303030;
  color: #fff;
  @include fontSize(17px);
  text-align: center;
  padding: 2rem 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 60;
  
  p {
    font-weight: 400;
    line-height: 2;
    text-align: left;
    margin-bottom: 0;
  }

  .btn {
    text-decoration: none !important;
    @include radius(6px);
    background-color: $colorSecondary !important;
    border-color: $colorSecondary;

    @include setStyleMinRwd(800px){
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    @include mobile{
      display: block;
      width: 100%;
      @include fontSize(16px);
      margin-top: 10px;
    }
  }

  &-wrapper{
    @extend %setFlex;
    align-items: flex-end;
    justify-content: space-around;
  }
  
  &.display {
    display: block;
    animation: cookies 1s 1;
  }

  &.close-cookies {
    transition: all 0.2s ease-out;
    transform: translate3d(0, 100%, 0);
    @include pad {
      bottom: -2%;
    }
  }

  @include setStyleMinRwd(1200px){
    .btn {
      margin-left: 2.5rem;
    }
  }

  @include setMinRwd{
    .btn {
      flex: 0 0 150px;
      @include fontSize(20px);
      margin-bottom: 0.5rem;
    }
  }

  @include pad {
    width: calc(100% - 1em);
    border-top: none;
    margin: .5em;
    padding-top: 1rem;
    padding-bottom: 1rem;
    @include fontSize(14px);
    
    &.display { 
      flex-direction: column;
    }
  }
}

@keyframes cookies {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  100% {
    transform: translate3d(0, 0%, 0);
  }
}