.paper{
    &-wrap{

    }

    &-yearSelect{
        display: flex;
        align-items: center;
        .unit{
            flex: none;
            padding-right: 15px;
        }
        .select_style{
            width: 100%;
            max-width: 300px;
            &::after {
                right: 11px;
                top: 50%;
                transform: translateY(-50%);
            }
            .form-control{
                height: 50px !important;
            }
        }
    }

    &-title{
        margin-top: 2em;
        margin-bottom: 2em;
        .title__underLine{
            @extend .fw-900;
            padding-bottom: 20px;
        }

        @include setMinRwd{
            margin-top: 4em;
        }
    }

    &-list{
        .box{
            border: #BBBBBB 1px solid;
            margin-top: 10px;
            margin-bottom: 25px;
            padding: 20px;

            @include mobile{
                padding: 10px;
                img{
                    max-width: 100px;
                }
            }
        }
    }

    &-btn{
        .btn{
            background-color: #F4F4F4;
            margin-right: 10px;
            padding-top: 0.75em;
            padding-bottom: 0.75em;
            @include radius(0);
            &:hover{
                background-color: $colorBlack;
            }

            @include mobile{
                width: 100%;
                padding-left: 0.5em !important;
                & ~ .btn{
                    margin-top: 10px;
                }
            }
        }
    }
}