// Theme color

/* #Navigation
================================================== */

.start-header {
	opacity: 1;
	transform: translateY(0);
    z-index: 2;
    .container{
        max-width: 100%;
        
        img{
            margin: 0;
        }
    }

    @include setMaxRwd{
        z-index: 9999;
    }
}

// 參考範例 : https://codepen.io/typo3-freelancer/pen/poEvyGj

@keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
}

// Assigning the above animation to all dropdown menus
.dropdown-menu{
    border: $colorBlack 1px solid;
    z-index: 3;
    &.show{
         animation: fadeIn 0.3s alternate;
    }
}

.nav-link{
    @include fontSize(19px);
    padding: 10px 15px !important;
    
    &,
    &:hover,
    &.active{
        color: #000 !important;
    }

    @include setStyleRwd(1700px){
        @include fontSize(18px);
        padding: 10px !important;
    }

    @include setWidthRwd(1020px,1400px){
        @include fontSize(16px);
    }

    @include setWidthRwd(1020px,1100px){
        padding-top: 5px !important;
        padding-bottom: 5px !important;
    }
}

// Mega Menu adjustments
.nav-item{
    margin-left: 5px;

    img{
        margin: 0;
    }

    @include setMinRwd{
        position: static !important;
        display: flex;
        align-items: center;
        height: 100%;
        min-height: 135px;
    }

    // 主連結
    &-link{
        &,
        .nav-link{
            position: relative;
        }

        .nav-link{
            & ~ .dropdown-menu{
                @include radius(0);
            }

            @include setWidthRwd(1020px,1100px){
                &::after {
                    top: 90%;
                }
            }

            @include pad{
                & ~ .dropdown-menu{
                    border: none;
                    margin-right: 18px;
                    margin-left: 12px;
                    margin-top: 0;
                    padding-top: 0;
                    .dropdown-menu-list{
                        li{
                            border-bottom: #eee 1px solid;
                        }
                    }
                }
            }
        }

        @include setStyleMinRwd(1020px){
            &.active,
            &:hover{
                .nav-link{
                    border: $colorMain 1px solid;
                    border-bottom-width: 2px;
                    @include radius(6px);
                    color: $colorSubsCont !important;
                }
            }

            &:hover{
                .nav-link{
                    & ~ .dropdown-menu{
                        width: 100vw;
                        left: -1em;
                        top: 70%;
                        z-index: -1;

                        border: none;
                        background-color: transparent;
                        padding-top: 0;
                        padding-bottom: 0;
                        .dropdown-menu-list{
                            display: flex;
                            justify-content: center;
                            border-top: 1px #ccc solid;
                            background: #fff;
                            margin-top: 2.5em;
                        }

                        // 連結
                        .dropdown-item{
                            @include fontSize(17px);
                            padding: 2rem 3rem;
                            background-color: transparent !important;
                            &:hover{
                                font-size: 1.1vw;
                                color: #064283;
                                padding-top: 1.75rem;
                            }
                        }
                    }
                }

                & > .dropdown-menu{
                    display: block;
                }
            }
        }
    }

    // 語言
    &-lang{
        display: flex;
        align-items: center;
        margin-left: 2.25em;
        .nav-link{
            @include fontSize(16px);
            color: $colorBlack !important;
            line-height: 1.75;
            @include radius(6px);
            @extend .fw-900;
            border: $colorBlack 1px solid;
            padding: 4px 8px !important;
        }

        .icon{
            margin-top: -6px;
            margin-right: 3px;
        }

        .dropdown{
            &-menu {
                @include setMinRwd{
                    min-width: 100px;
                    @include radius(0);
                    padding: 0;
                    top: 70%;
                    right: 0.5%;
                    left: auto;
                }
            }

            &-item{
                padding: 5px 22px 7px;
                &:hover,
                &:focus{
                    background-color:$colorMain;
                    color: #fff;
                }
            }
        }

        @include pad{
            margin-left: 1em;
            margin-right: 1em;
            margin-top: 1em;
            .nav-link {
                display: none;
            }

            .dropdown{
                &-menu {
                    min-width: auto;
                    width: 100%;
                    border-radius: 0;
                    padding: 0;
                    display: flex;
                    justify-content: space-around;
                    li{
                        flex: 1;
                        text-align: center;
                    }
                }
            }
        }
    }
}

.navbar{
    padding-top: 0;
    padding-bottom: 0;

    @include setMaxRwd{
        margin-left: -15px;
        margin-right: -15px;
    }

    .container-fluid{
        @include setWidthRwd(900px,1250px){
            flex-direction: column;
        }
    }

    &-collapse{
        @include setMinRwd{
            position: static;
        }

        @include setWidthRwd(900px,1250px){
            width: 100%;
        }
    }

    &-nav{
        @include setWidthRwd(900px,1250px){
            justify-content: space-between !important;
        }
    }

    &-brand{
        @include setStyleRwd(1700px){
            width: 22.5vw;
        }

        @include setStyleRwd(1200px){
            width: auto;
        }

        @include pad{
            width: 15vw;
        }

        @include mobile{
            width: 70vw;
        }
    }

    // Complete styles for main navbar toggler and hamburger menu
    &-toggler{
        border: none;
        padding: 0;
        outline: none;
        &:focus{
            box-shadow: none;
        }
        
        .hamburger-toggle{
            position: relative;
            display: inline-block;
            width: 50px;
            height: 50px;
            z-index: 11;
            .hamburger {
                position: absolute;
                transform: translate(-50%, -50%) rotate(0deg);
                left: 50%;
                top: 50%;
                width: 50%;
                height: 50%;
                pointer-events: none;
                span {
                    width: 100%;
                    height: 4px;
                    position: absolute;
                    background: $colorBlack;
                    border-radius: 2px;
                    z-index: 1;
                    transition: transform 0.2s cubic-bezier(0.77,0.2,0.05,1.0), background 0.2s cubic-bezier(0.77,0.2,0.05,1.0), all 0.2s ease-in-out;
                    left: 0px;
                    &:first-child{
                        top: 10%;
                        transform-origin: 50% 50%;
                        transform: translate(0% -50%) !important;
                    }
                    &:nth-child(2){
                        top: 50%;
                        transform: translate(0,-50%);
                    }
                    &:last-child{
                        left: 0;
                        top: auto;
                        bottom: 0;
                        transform-origin: 50% 50%;
                    }
                }

                &.active{
                    span {
                        position: absolute;
                        margin: 0;
                        &:first-child{
                            top: 45%;
                            transform: rotate(45deg);
                        }
                        &:nth-child(2){
                            left: 50%;
                            width: 0px;
                        }
                        &:last-child{
                            top: 45%;
                            transform: rotate(-45deg);
                        }
                    }
                }
            }
        }

        // 
        &.fixed{
            display: none;
            position: fixed;
            left: 62%;
            top: 2%;
            z-index: 1001;
            .hamburger-toggle{
                .hamburger{
                    span {
                        background-color: #fff;
                        &:first-child{
                            transform: rotate(40deg);
                        }

                        &:last-child{
                            transform: translateY(-18px) rotate(-40deg);
                        }
                    }
                }
            }
            
            &.active{
                display: block;
            }
            
        }
    }

    &-collapse{
        @include pad{
            position: fixed;
            @include setSize(100%,100vh);
            top: 0;
            left: -100%;
            background: #fff;
            transition: 0.15s;
            overflow-y: auto;
            max-width: 60vw;
            &,
            &>ul{
                z-index: 100;
            }

            &>ul{
                position: relative;
                justify-content: flex-start !important;
                min-height: 100%;
            }

            &.show{
                left: 0;
            }
        }
    }

    &-overlay{
        display: block;
        @include setSize(100%,100vh);
        background-color: rgba(0,0,0,0.75);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;
        opacity: 0;
        visibility: hidden;

        &.active{
            opacity: 1;
            visibility: visible;
        }

        @include setMinRwd{
            display: block;
        }
    }

    // 下拉
    &-mobile-icon{
        @include setSize(80px,40px);
        color: #000 !important;
        text-align: right;
        padding-right: 18px;
        position: absolute;
        right: 0;
        top: 5px;
        &::after {
            display: none;
        }

        // &.show{
        //     .bi{
        //         transform: rotate(180deg);
        //     }
        // }
    }
}

.dropdown-item{
    white-space: normal;
    padding-top: 0.75em;
    padding-bottom: 0.75em;
}

.icons{
  display: inline-flex;
  margin-left: auto;
  a{
    transition: all 0.2s ease-in-out;
    padding: 0.2rem 0.4rem;
    color: #ccc !important;
    text-decoration: none;
    &:hover{
      color: white;
      text-shadow: 0 0 30px white;
    }
  }
}