.index{

    // 內框
    &-content{
        padding-top: 1.5em;
        padding-bottom: 2em;

        @include setStyleRwd(1400px){
            padding-left: 0.5em;
            padding-right: 0.5em;
        }

        @include setMaxRwd{
            padding-top: 1em;
            padding-bottom: 1em;
        }
    }

    // 頁面使用
    &-page{
        &News{
            @include setBackSet(top,cover);
            background-image: url('../images/index-pageNews.jpg');
            padding-top: 20px;
            margin-top: -6em;

            @include setMaxRwd{
                margin-top: -2em;
            }
        }

        &Act{
            @include setBackSet(left top,contain);
            background-image: url('../images/index-pageAct.jpg');
            background-color: #deeefb;
            @include setMinRwd{
                padding-top: 2em;
            }

            .title{
                &-wrap {
                    display: inline-block;
                    margin-left: 1.5em;
                    margin-top: 1.5em;
                }

                &-ch{
                    color: #fff
                }
            }

            .btn{
                &-wrap{
                    @include setMinRwd{
                        &-indexAct{
                            justify-content: flex-end;
                            position: absolute;
                            right: 15px;
                            top: 47px;
                        }
                    }
                }
                
                &-outline-primary{
                    border-color: #064583;
                    color: #064583;
                    &:hover{
                        color: #fff
                    }
                }
            }
        }

        &Link{
            @include setBackSet(top,cover);
            background-image: url('../images/index-pageLink.jpg');

            &-swiper{
                overflow: hidden;
            }

            .swiper{
                &-container{
                    padding-top: 1.25em;
                    padding-bottom: 1.25em;
                    overflow: hidden;
                }

                &-wrapper{
                    /*通过改变animation-timing-function 制作弹性切换效果*/
                    transition: 2s cubic-bezier(0.68, -0.4, 0.27, 1.34) 0.2s;
                }

                &-slide{
                    @include setMaxRwd{
                        padding: 1em;
                    }
                }

                &-button-next,
                &-button-prev {
                    color: #000;
                }
                
                @include setMaxRwd{
                    &-button-next,
                    &-button-prev {
                       &::after{
                        @include fontSize(30px);
                       } 
                    }

                    &-button-next{
                        right: 0
                    }

                    &-button-prev {
                        left: 0
                    }
                }
            }

            &-wrap{
                @include maxWidth(1100px);
                background-color: #fff;
                @extend .boxShadow;
                padding: 1.5em 3em;

                @include setMaxRwd{
                    padding-left: 1.5em;
                    padding-right: 1.5em;
                }
            }

            &-title{
                display: inline-block;
                color: #cb3d2e;
                @include fontSize(20px);
                border-bottom: #cb3d2e 1px solid;
                margin-bottom: 10px;
                padding-bottom: 5px;
                @extend .fw-900;
            }

            &-txt{
                color: #6c6c6b;
                @include fontSize(18px);
            }

            @include setMaxRwd{
                &-title{
                    @include fontSize(14px);
                }
    
                &-txt{
                    @include fontSize(13px);
                }
            }
        }
    }
}

// 共用Banner形象圖
.page{
    &-banner{
        width: 100%;
        overflow: hidden;
        z-index: 1;

        @include setWidthRwd(900px,1100px){
            margin-top: 1%;
        }

        .bannerSwiper{
            @include setMinRwd{
                padding-bottom: 3em;
                &::before{
                    @include setBackSet(left,contain);
                }
            }
        }

        .swiper{
            &-pagination{
                &-bullets {
                    bottom: 0 !important;
                    @include setMinRwd{
                        width: auto !important;
                        left: auto !important;
                        bottom: auto !important;
                        right: 1.5em;
                        top: 50%;
                        transform: translate3d(0px, -50%, 0);
                        border: #fff 1px solid;
                        padding: 0.5em;
                        border-radius: 1em;
                    }

                    @include setMaxRwd{
                        bottom:-1.5em !important
                    }
                }
                &-bullet {
                    width: 12px !important;
                    height: 12px !important;
                    background-color: #000;
                    &-active{
                        background-color: $colorMain !important;
                        border-radius: 0.75em !important;
                    }
                    
                    @include setMinRwd{
                        display: block;
                        margin: 0.3em 0 !important;
                        transition: height 200ms ease-in;
                        background-color: #fff;
                        opacity: 1;
                        &-active{
                            height: 1.75em !important;
                        }
                    }

                    @include setMaxRwd{
                        &-active{
                            width: 1.75em !important;
                            margin: 0 0.3em !important;
                        }
                    }
                }
            }
        }

        &-main{
            @include mobile{
                img{
                    height: 100px;
                    object-fit: cover;
                }
            }
        }
    }
}
  
.animationItem {
    transform: translate3d(-50px, 0, 0);
    opacity: 0;
    transition: 0.85s cubic-bezier(0.31, 1.26, 0.19, 1.11);
}
  
.animationItem.animated {
    transform: translate3d(0, 0, 0);
    opacity: 1;
}