// -------- BS -------- //
.form-control,
.btn{
    border-radius: 0.5em;
}

.form-control{
    height: auto;
    min-height: 50px;
    &,
    &:focus{
        
    }
}

.modal {
    text-align: center;
    padding-right: 0 !important;

    &-content{
        border-color: #accccd;
        border-width: 3px;
    }

    &-open{
        padding-right: 0 !important;
    }

    @include setMinRwd{
        text-align: center;
        padding: 0!important;
        &:before {
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
            margin-right: -4px;
        }
        &-dialog {
            display: inline-block;
            text-align: left;
            vertical-align: middle;
        }
    }

    @include  setStyleMinRwd(500px){
        &-dialog {
            width: 100%;
            max-width: 85rem;
        }
    }
    @include setWidthRwd(1100px,1500px){
        &-dialog {
            max-width: 70rem;
        }
    }

    &-autoheight{
        .modal-body {
            position: relative;
            overflow-y: auto;
            overflow-x: hidden;
            min-height: 100px !important;
            max-height: 100vh !important;
            @include setStyleMinRwd(500px){
                max-height: calc(100vh - 50px) !important
            }
            @include pad1080{
                height: calc(90vh - 1.5rem) !important;
            }
        }
        @include pad1080{
            transform: translateY(5%);
        }
    }
}