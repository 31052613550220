// 聯絡我們
.contact{
    &-bg{
        @include setBackSet(top left,auto);
        background-image: url('../images/contact_bgInfo.png');
        @include setMinRwd{
            padding-bottom: 120px;
        }

        @include setWidthRwd(1251px,1450px){
            padding-bottom: 140px;
        }

        @include pad1080{
            background-size: 100%;
            padding-bottom: 0;
        }
    }

    &-wrapper{
        position: relative;
        @extend %setFlex-center;
        @include setMinRwd{
            padding-top:1em;
            padding-bottom:1em;
        }
    }
    
    // 基本資訊使用
    &-info{
        flex: 1;
        margin-top: 10px;

        &-txt{
            width: 100%;
            border: #777 1px solid;
            background-color: #fff;
            padding: 1.75em 2.25em 3em;
            margin-bottom: 1em;

            @include setMaxRwd{
                padding: 1em;
            }
        }
    }

    // 表單使用
    &-form {
        width: 100%;
        background: #fff;
        padding-top: 1rem;
        padding-bottom: 1rem;
        legend{
            color:$colorBase;
            line-height:1.5;
            @include fontSize(22px);
            margin-bottom: 20px;
            @include setMinRwd{
                padding-left: 2em;
            }
        }

        label{
            color: #777;
            margin-bottom: 0;
        }

        .form-control{
            &,
            &:hover,
            &:focus{
                border: none;
                outline: none;
                box-shadow: none;
                background-color: #fff;
                border-radius: 0;
            }

            &:hover,
            &:focus{
                border-color: #000;
            }

            &.form-border{
                border:#c9c9c9 1px solid;
                @include radius(6px);

                @include setMaxRwd{
                    width: 100% !important;
                    flex: 1 !important;
                }
            }
        }

        .form-group{
            display: flex;
            border-bottom: 1px solid #ddd;
            margin-bottom: 5px;
            label{
                flex: none;
                width: 90px;
                padding-left: 10px;
            }

            @include pad1080{
                flex-direction: column;
                margin-bottom: 0.5em;
            }
        }

        .btn-wrap{
            li{
                display: flex;
                justify-content: center;
                width:100%;
            }
        }
    }

    // 格線調整
    &-col{
        &-left{
            @include setMinRwd{
                padding-right: 40px;
            }
        }
    }
    
}


