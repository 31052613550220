// 按鈕Hover的狀態
%btnMainHover{
    background-color: $colorMain;
    border-color: $colorMain;
    color: #fff;
}

%btnMainHover,
%btnoutlineHover{
    background-color: $colorSubs !important;
    border-color: $colorSubs !important;
    color: #fff !important;
}

// 按鈕樣式
.btn{
    @include fontSize(20px);
    padding: 0.5em 1.25em;
    @include radius(0px);
    font-weight: 100;

    .fa{
        font-weight: 100;
        vertical-align: middle;
        margin-top: -3px;
        margin-left: 6px;
    }

    .icon {
        vertical-align: top;
        margin-top: -2px;
        margin-left: 3px;
        margin-right: 3px;
    }

    &-primary{
        background-color: $colorSecondary;
        border-color: $colorSecondary;
    }

    &-icon{
        position: relative;
        @include radius(0.3rem);
        padding-right: 50px !important;
        .icon {
            position: absolute;
            top: 50%;
            right: 13px;
            transform: translateY(-50%);
        }

        &-left{
            padding-right: 30px !important;
            padding-left: 60px !important;
            .icon {
                left: 20px;
                right: auto;
            }
        }
    }

    // 包按鈕的框
    &-wrap {
        @extend %setFlex-center;
        justify-content: center;
        width: 100%;
        margin: 2rem 0 1rem;
        &>li {
            flex: 1;
            &+li {
                margin-left: 1.5rem;
            }
        }
        @include setMinRwd{
            flex-wrap: nowrap;
        }

        @include pad1080 {
            margin-top: 1rem;
            &>li {
                &+li {
                    margin-left: 0.5rem;
                }
            }
        }
        
        @include mobile {
            &>li {
                flex: none;
                width: 100%;
                &+li {
                    margin-top: 1em;
                }
            }
        }
    }
}

.js-toggle-btn{
    .btn{
        @include fontSize(16px);
        @include radius(20px);
        border: #eee 1px solid;
        i{
            color: currentColor;
        }

        .icon{
            margin-top: 0;
        }
    }
}
