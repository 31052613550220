.inMain{
    &-about{
        .article{
            @include fontSize(20px);
            line-height: 1.8;
        }

        &-avator{
            &-left,
            &-right{
                @include radius(10px);
                padding: 0.95em;
                overflow: hidden;

                display: flex;

                @include setStyleMinRwd(800px){
                    align-items: center;
                }

                @include mobile{
                    margin-bottom: 1em;
                    img{
                        width: 100px;
                    }
                }
            }

            &-left{
                background-color: #dae7f0;
            }

            &-right{
                background-color: #dceeec;
            }
        }

        &-menu{
            display: grid;
            grid-auto-flow: column;
            // gap: 15px;
            margin-top: 30px;
            margin-bottom: 30px;
            li{
                position: relative;
                color: #717171;
                line-height: 1.25;
                &:not(:last-child){
                    &::after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        right: 30px;
                        width: 1px;
                        height: 100%;
                        background: #636363;
                        transform: translate(50%, -50%);
                    }
                }
            }

            @include setStyleMinRwd(820px){
                grid-template-columns: 350px 350px 350px 350px;
                li{
                    &:not(:last-child){
                        &::after{
                            right: 31px;
                        }
                    }
                }
            }

            @include pad{
                grid-auto-flow: row;
            }
        }

        &-collapse{
            .accordion-item{
                border: none;
                margin-bottom: 1em;
            }

            .accordion-button{
                @include fontSize(18px);
                @extend .fw-900;
                background: #f5f5f5;
                padding: 1em 1.25em;

                &,
                &::after {
                    @extend .text-main;
                }

                &::after {
                    content: '\F64D';
                    font-family: "bootstrap-icons" !important;
                    background-image: none;
                }

                &:not(.collapsed){
                    &::after{
                        content: '\F63B';
                    }
                }
            }

            .accordion-button,
            .accordion-body{
                @include setMinRwd{
                    @include fontSize(20px);
                }
            }

            &Item{
                @extend %setFlex;
                align-items: stretch;
                padding: 0.875em 0;
                position: relative;
                z-index: 1;
                border-bottom: 1px solid #d9d9d9;
                p {
                    margin-bottom: 0;
                }

                .num{
                    @extend .fw-900;
                    margin-top: -3px;
                    @include setMinRwd{
                        @include fontSize(22px);
                    }
                }

                @include setMinRwd{
                    flex-wrap: nowrap;
                    padding: 20px 10px;
                    .num{
                        flex: none;
                    }

                    &,
                    .text{
                        padding-left: 50px;
                    }
                }
            }
        }

        // 理監事名單
        &-supervisorList{
            &,
            .box {
                padding: 10px;
            }

            border: #D8D8D8 3px solid;
            margin-top: 1.25em;
            margin-bottom: 2.5em;
            padding-bottom: 5px;

            .box {
                background-color: #fff;

                &:nth-child(odd){
                    background-color: #F4F4F4;
                }

                .unit{
                    display: inline;
                    padding-right: 0;
                }

                @include setMinRwd{
                    &-head{
                        min-width: 125px;
                        padding-left: 15px;
                    }
                }

                @include setMaxRwd{
                    &-head{
                        min-width: 100%;
                    }
                }
            }

            &.current{
                border-color: $colorSecondary;
                .box{
                    &:nth-child(odd){
                        background-color: #EEF3F7;
                    }
                }
            }
        }
    }
}