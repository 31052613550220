// ----- 文字區塊 or 編輯器 ----- //
.article,
article {
    width: 100%;
    font-size: 100%;
    iframe {
        width: 100%;
        height: 750px;
        @include pad {
            height: 350px;
        }
    }
}

// Google 驗證
.g-recaptcha iframe{
    height: auto !important;
}

// 文字粗體
.fw-900 {
    font-weight: 900 !important;
}

// 邊框陰影
.boxShadow{
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1) !important;
}

// 邊框
.bt-solid-c1{
    border-top: 1px #ccc solid;
}

// -------- OTHER -------- //
.unit{
    position: relative;
    display: block;
    line-height: 1.5;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    .btn{
        padding-top: 0.6em;
        padding-bottom: 0.6em;
    }

    &-icon{
        padding-left: 1.5em !important;
        padding-right: 0;
        .icon{
            margin-top: 0;
            position: absolute;
            left: 0;
            top: -0.1rem;
        }
    }

    @include setMaxRwd{
        text-align: left;
        font-size: 16px;
        &-icon{
            .icon{
                top: 0.85rem;
            }
        }
    }
}

.select_style {
    &::after {
        content: '\F282';
        font-family:'bootstrap-icons' !important;
        display: block;
        @include fontSize(14px);
        position: absolute;
        right: 8px;
        top: 1px;
    }

    select.form-control {
        border: 1px solid #e6eaea !important;
        text-align: left;
        @include radius(0);
        height: 40px !important;
        min-height: auto;
        padding-top: 0;
        padding-bottom: 0; 
    }

    select {
        width: 100%;
        background: transparent;
        appearance: none;
        -webkit-appearance: none;
    }
    select,
    input {
        border: none !important;
        height: 100% !important;
    }
    .form-control {
        border-radius: 0;
    }
    .disabled {
        background-color: #e9ecef;
        select,
        input {
            background: transparent;
        }
    }
}

// -------- 標題樣式 -------- //
// 內容標題
.title{
    &-wrap{
        text-align: center;
        padding: 10px;
        margin-top: 10px;
        margin-bottom: 10px;

        @include setMaxRwd{
            margin-bottom: 2px;
        }
    }

    // 中英文字
    &-en{
        color:#d8d7d8;
        @include fontSize(30px);
        font-family: $fontNotosanc-tc;
        text-transform: uppercase;
        margin-bottom: 0;
        @extend .fw-900;

        @include pad{
            font-size: get-vw-mobile(40px);
        }
    }

    &-ch{
        position: relative;
        @include fontSize(45px);
        @extend .fw-900;
        color: #595757;

        @include pad{
            font-size: get-vw-mobile(28px);
        }

        @include mobile{
            font-size: get-vw-mobile(48px);
        }
    }

    &-left-circle{
        position: relative;
        padding-left: 15px;
        z-index: 1;
        &::before {
            content: "";
            position: absolute;
            background-color: #04cafc;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            top: 50%;
            left: -15px;
            margin-top: -45px;
            z-index: -1;
        }
    }

    &-main{
        &Wrap{
            @extend %setFlex-center;
            justify-content: space-between;
            padding-top: 0.25em;
            @include setMaxRwd{
                padding-top: 0.65em;
                padding-bottom: 0.65em;
            }
        }
    }

    // 底線
    &__underLine{
        border-bottom-width: 2px;
        border-bottom-style: solid;
        border-bottom-color: #000;
    }
}

// 主標題
.heading-title{
    // @include fontSize(35px);
    font-size:get-vw(35px);
    @extend .fw-900;

    @include mobile{
        @include fontSize(25px);
    }

    &-wrap{
        @extend %setFlex-center;
        @include setMinRwd{
            flex-wrap: nowrap
        }
    }
}

// 麵包屑
.breadcrum-wrap {
    .breadcrumb {
        @include fontSize(16px);
        color: #404040;
        background-color: transparent;
        margin-bottom: 0;
        padding: 0.75rem;
        padding-right: 0;
        .breadcrumb-item{
            color: #777;
            &+.breadcrumb-item{
                &::before {
                    content: "\f280";
                    font-family: "bootstrap-icons" !important;
                    padding-right: 0.65em;
                }
            }
            &.active{
                color: black;
            }
        }

        @include setWidthRwd(1101px,1350px){
            padding-left: 3em;
        }

        @include setMaxRwd{
            padding-left: 0;
            padding-top: 0.3em;
            padding-bottom: 0;
        }
    }

    .icon{
        margin-top: -3px;
    }
}

// 頁碼
.pagination-wrap {
    @extend %setFlex;
    justify-content: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
    .pagination {
        justify-content: center;
        .page-link {
            @include setSize(45px,45px);
            @include fontSize(20px);
            line-height: 45px;
            color: #6e6d6d;
            font-family: $Roboto;
            background-color: transparent;
            border-color: #7d7d7d;
            @include radius(0);
            text-align: center;
            padding: 0;
            margin-right: 0.5rem;
            &:hover,
            &.active {
                color: #fff;
                background-color:$colorBlack;
                border-color:$colorBlack;
            }
        }
    }

    .pagination-select{
        .select_style{
            width: 100%;
            min-height: auto;
            &::after{
                top: 50%;
                transform: translateY(-50%);
            }
        }

        @include setMinRwd{
            width: 60px;
        }

        @include setMaxRwd{
            display: flex;
            justify-content: center;
            width: 175px;
        }
    }

    @include setMaxRwd{
        margin-top: 0.5rem;
        margin-bottom: 2rem;
    }
}

//-------- 文字 --------//
// 顏色
$textColor-colors: (Main: #064583,Link: #1d2088,Normal: #5d5d5e, Base: $colorBase);

.text{
    &-main {
        color: map-get($textColor-colors , Main) !important;
    }
}

// 左右對齊
.justify{
    text-align: justify;
}

//-------- 自訂 --------//
.flex{
    &-none{
        flex: none !important;

        @include mobile{
            width: 100%;
        }
    }

    &-auto{
        flex: auto !important
    }

    &-1{
        flex: 1 !important
    }
}

// 項目
.tag-item {
    display: inline-block;
    width: auto;
    line-height: 1.1;
    margin: 2.5px;
    font-size: 14px;
    line-height: 1;
    padding: 6px;
    text-align: center;
    background-color: $bgSecondary;
    color: $colorText; 

    &-current,
    &-date{
        @extend .tag-item;
        @include fontSize(18px);
        color: #fff;
        padding: 8px 16px;
        @include radius(6px);
    }

    &-current{
        background-color: #00b7ee;
    }

    &-date{
        background-color: #bfbf85;
    }

    &-wrap{
        .tag-item{
            & ~ .tag-item{
                margin-left: 5px;
            }
        }

        @include setMinRwd{
            padding-left: 15px;
        }

        @include setMaxRwd{
            padding-top: 10px;
        }
    }
}