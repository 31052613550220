@charset "UTF-8";
@import url("//fonts.googleapis.com/css2?family=Noto+Sans+TC&display=swap");
@import url("//fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("//fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("//fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap");
@import url("//fonts.googleapis.com/css2?family=Kanit:wght@700&display=swap");
a, body {
  color: #222222;
  font-size: 18px;
  font-family: "Noto Sans TC", "Microsoft JhengHei", "微軟正黑體", "Arial", sans-serif;
  line-height: 1.65;
  font-weight: 400;
}

.sprite, input, form, body, html {
  margin: 0px;
  padding: 0px;
}

.dowload-item, .inMain-about-collapseItem, .card-wrap, .box, .pagination-wrap, .cookies-wrapper, footer .wrapper {
  display: flex;
  flex-wrap: wrap;
}

.contact-wrapper, .dowload-list li, .card-time, .btn-wrap, .heading-title-wrap, .title-mainWrap, footer .footer-info-wrap, footer .footer-icon, .pageloading {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.card-wrap-mainLink .card-img-top img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 100%;
  margin: auto;
}

.font-size-1 {
  font-size: 87.5% !important;
}

.font-size-2 {
  font-size: 100% !important;
}

.font-size-3 {
  font-size: 112.5% !important;
}

.font-size-4 {
  font-size: 150% !important;
}

.font-size-5 {
  font-size: 175.5% !important;
}

.font-size-6 {
  font-size: 250% !important;
}

.font-size-7 {
  font-size: 275% !important;
}

.font-size-8 {
  font-size: 362.5% !important;
}

.font-size-9 {
  font-size: 450% !important;
}

.line-height-1 {
  line-height: 1 !important;
}

.line-height-2 {
  line-height: 1.25 !important;
}

.line-height-3 {
  line-height: 1.5 !important;
}

.line-height-4 {
  line-height: 1.75 !important;
}

.line-height-5 {
  line-height: 2 !important;
}

.noJS > div,
.noJS > ul {
  display: none;
}

.noJS > noscript {
  display: block;
  width: 100%;
  line-height: 10em;
}

* {
  box-sizing: border-box;
}

html {
  overflow: -moz-scrollbars-vertical;
}

body {
  background: #fff;
  visibility: visible;
  overflow-x: hidden;
}
body.fixed {
  overflow: hidden;
}
@media (max-width: 1600px) {
  body {
    font-size: 18px;
  }
}

div {
  position: relative;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

.sh_sans {
  font-family: "source-han-sans-traditional", "微軟正黑體", sans-serif;
}

.sh_serif {
  font-family: "source-han-serif-tc", serif;
}

form {
  font-size: 16px;
}

label {
  display: inline-flex;
  align-items: center;
  margin-right: 12px;
  cursor: pointer;
}

input {
  -webkit-autofill: unset;
  font-size: 16px;
  background-color: #eeeeee;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #fcfcfc inset;
}
input:focus {
  outline: 0;
}
input[type=text], input[type=password] {
  vertical-align: top;
}
input[type=radio], input[type=checkbox] {
  margin-right: 5px;
  cursor: pointer;
  vertical-align: middle;
}
input[type=number]::-webkit-inner-spin-button,
input [type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
input.hid {
  display: none;
}

a:focus,
input:focus,
select:focus,
button:focus {
  box-shadow: none !important;
  outline: none;
}

select {
  border: 0px;
  outline: none;
}

textarea {
  padding: 5px;
  border: 1px solid rgba(216, 216, 216, 0.8);
  box-sizing: border-box;
  resize: none;
  font-size: 16px;
  background-color: #eeeeee;
}

a {
  cursor: pointer;
  color: inherit;
  font-size: inherit;
  text-decoration: none;
}
a:hover, a:focus {
  text-decoration: none;
  color: currentColor;
}

img {
  max-width: 100%;
}

.sprite {
  fill: #23ccf2;
  vertical-align: top;
  width: 100%;
  height: 100%;
  transition: 0.3s ease-in-out;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.font-button .far,
.font-button .fas {
  color: #666;
  transition: 0.08s cubic-bezier(0.37, 0.99, 0.92, 0.96);
}
.font-button:hover .far,
.font-button:hover .fas {
  color: #23ccf2;
}

.zero-size {
  font-size: 0px;
  letter-spacing: 0px;
  word-spacing: 0px;
}

.required {
  color: #23ccf2;
  margin: 0 0 0 8px;
}

.red {
  color: #ff0016;
}

.space {
  display: inline-block;
  width: 7px;
}

.clear {
  clear: both;
  height: 0px;
  line-height: 0px;
  font-size: 0px;
  overflow: hidden;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hide {
  display: none !important;
}
@media (max-width: 1024px) {
  .hide {
    display: block !important;
  }
}

@keyframes menushow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes visitsLoad {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  30% {
    transform: translate3d(-70%, 0, 0);
  }
  80% {
    transform: translate3d(-20%, 0, 0);
  }
  95% {
    transform: translate3d(-5%, 0, 0);
  }
  100% {
    transform: translate3d(0%, 0, 0);
  }
}
@keyframes visitsShow {
  0% {
    opacity: 0;
    transform: translate3d(0, 45px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes moveCircle {
  0%, 100% {
    transform: translateY(-10%);
  }
  50% {
    transform: translateY(-5%);
  }
}
.table {
  border-top: 1px solid #c8dae8;
  border-right: 1px solid #c8dae8;
  border-left: 1px solid #c8dae8;
}

.input {
  width: 100%;
}

.textarea {
  width: 100%;
  height: 100px;
}

.searchBar {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.icon {
  display: inline-block;
  vertical-align: middle;
}

.mw-auto {
  max-width: none !important;
}

.wrapper-1800 {
  width: 100%;
  max-width: 1800px;
  margin: auto;
}

.wrapper-1750 {
  width: 100%;
  max-width: 1750px;
  margin: auto;
}

.wrapper-1700 {
  width: 100%;
  max-width: 1700px;
  margin: auto;
}

.wrapper-1650 {
  width: 100%;
  max-width: 1650px;
  margin: auto;
}

.wrapper {
  width: 100%;
  max-width: 1600px;
  margin: auto;
}

.wrapper-1550 {
  width: 100%;
  max-width: 1550px;
  margin: auto;
}

.wrapper-1500 {
  width: 100%;
  max-width: 1500px;
  margin: auto;
}

.wrapper-1450 {
  width: 100%;
  max-width: 1450px;
  margin: auto;
}

.wrapper-1400 {
  width: 100%;
  max-width: 1400px;
  margin: auto;
}

.wrapper-1350 {
  width: 100%;
  max-width: 1350px;
  margin: auto;
}

.wrapper-1300 {
  width: 100%;
  max-width: 1300px;
  margin: auto;
}

.wrapper-1250 {
  width: 100%;
  max-width: 1250px;
  margin: auto;
}

.wrapper-1200 {
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.wrapper-1100 {
  width: 100%;
  max-width: 1100px;
  margin: auto;
}

.wrapper-1050 {
  width: 100%;
  max-width: 1050px;
  margin: auto;
}

.wrapper-1000 {
  width: 100%;
  max-width: 1000px;
  margin: auto;
}

.wrapper-900 {
  width: 100%;
  max-width: 900px;
  margin: auto;
}

.wrapper-800 {
  width: 100%;
  max-width: 800px;
  margin: auto;
}

.detail_label {
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
  padding: 0 6px;
  height: 20px;
  text-align: center;
  background-color: #fff;
  line-height: 20px;
  vertical-align: middle;
  font: 13px/25px Arial;
  border: 1px solid #fff;
}
.detail_label.fc-LightSalmon {
  border-color: #e9a3a8;
  color: #e9a3a8;
  line-height: 20px;
}

.pageloading {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  flex-direction: column;
  justify-content: center;
  transition: 0.2s 0.2s;
  z-index: 10000;
}
.pageloading .loading {
  width: 200px;
  height: 8px;
  background-color: #fff;
  margin-top: 10px;
  border-radius: 5px;
  overflow: hidden;
}
.pageloading .loading .bar {
  width: 0%;
  height: 100%;
  background-color: #23ccf2;
}
.pageloading.compelet {
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}

@keyframes jumping {
  50% {
    top: 0;
  }
  100% {
    top: -50px;
  }
}
@keyframes eyemove {
  0%, 10% {
    transform: translateX(50%);
  }
  90%, 100% {
    transform: translateX(-50%);
  }
}
.letter {
  float: left;
  font-size: 18px;
  font-size: 1.125rem;
  color: #000;
}

.letter-holder .letter {
  animation-name: loadingF;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: linear;
}

.l-1 {
  animation-delay: 0.48s;
}
.l-2 {
  animation-delay: 0.53s;
}
.l-3 {
  animation-delay: 0.58s;
}
.l-4 {
  animation-delay: 0.63s;
}
.l-5 {
  animation-delay: 0.69s;
}
.l-6 {
  animation-delay: 0.74s;
}
.l-7 {
  animation-delay: 0.79s;
}
.l-8 {
  animation-delay: 0.84s;
}
.l-9 {
  animation-delay: 0.89s;
}
.l-10 {
  animation-delay: 0.94s;
}

@keyframes loadingF {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.js-loadHide {
  display: none !important;
}

.loader {
  margin: 0 0 2em;
  height: 100px;
  width: 20%;
  text-align: center;
  padding: 1em;
  margin: 0 auto 1em;
  display: inline-block;
  vertical-align: top;
}

/* Set the color of the icon */
svg path,
svg rect {
  fill: #23ccf2;
}

/* #Navigation
================================================== */
.start-header {
  opacity: 1;
  transform: translateY(0);
  z-index: 2;
}
.start-header .container {
  max-width: 100%;
}
.start-header .container img {
  margin: 0;
}
@media (max-width: 1024px) {
  .start-header {
    z-index: 9999;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.dropdown-menu {
  border: #000 1px solid;
  z-index: 3;
}
.dropdown-menu.show {
  animation: fadeIn 0.3s alternate;
}

.nav-link {
  font-size: 19px;
  font-size: 1.1875rem;
  padding: 10px 15px !important;
}
.nav-link, .nav-link:hover, .nav-link.active {
  color: #000 !important;
}
@media (max-width: 1700px) {
  .nav-link {
    font-size: 18px;
    font-size: 1.125rem;
    padding: 10px !important;
  }
}
@media (min-width: 1020px) and (max-width: 1400px) {
  .nav-link {
    font-size: 16px;
    font-size: 1rem;
  }
}
@media (min-width: 1020px) and (max-width: 1100px) {
  .nav-link {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
}

.nav-item {
  margin-left: 5px;
}
.nav-item img {
  margin: 0;
}
@media (min-width: 1025px) {
  .nav-item {
    position: static !important;
    display: flex;
    align-items: center;
    height: 100%;
    min-height: 135px;
  }
}
.nav-item-link,
.nav-item-link .nav-link {
  position: relative;
}
.nav-item-link .nav-link ~ .dropdown-menu {
  border-radius: 0;
}
@media (min-width: 1020px) and (max-width: 1100px) {
  .nav-item-link .nav-link::after {
    top: 90%;
  }
}
@media (max-width: 810px) {
  .nav-item-link .nav-link ~ .dropdown-menu {
    border: none;
    margin-right: 18px;
    margin-left: 12px;
    margin-top: 0;
    padding-top: 0;
  }
  .nav-item-link .nav-link ~ .dropdown-menu .dropdown-menu-list li {
    border-bottom: #eee 1px solid;
  }
}
@media (min-width: 1020px) {
  .nav-item-link.active .nav-link, .nav-item-link:hover .nav-link {
    border: #23ccf2 1px solid;
    border-bottom-width: 2px;
    border-radius: 6px;
    color: #374a6f !important;
  }
  .nav-item-link:hover .nav-link ~ .dropdown-menu {
    width: 100vw;
    left: -1em;
    top: 70%;
    z-index: -1;
    border: none;
    background-color: transparent;
    padding-top: 0;
    padding-bottom: 0;
  }
  .nav-item-link:hover .nav-link ~ .dropdown-menu .dropdown-menu-list {
    display: flex;
    justify-content: center;
    border-top: 1px #ccc solid;
    background: #fff;
    margin-top: 2.5em;
  }
  .nav-item-link:hover .nav-link ~ .dropdown-menu .dropdown-item {
    font-size: 17px;
    font-size: 1.0625rem;
    padding: 2rem 3rem;
    background-color: transparent !important;
  }
  .nav-item-link:hover .nav-link ~ .dropdown-menu .dropdown-item:hover {
    font-size: 1.1vw;
    color: #064283;
    padding-top: 1.75rem;
  }
  .nav-item-link:hover > .dropdown-menu {
    display: block;
  }
}
.nav-item-lang {
  display: flex;
  align-items: center;
  margin-left: 2.25em;
}
.nav-item-lang .nav-link {
  font-size: 16px;
  font-size: 1rem;
  color: #000 !important;
  line-height: 1.75;
  border-radius: 6px;
  border: #000 1px solid;
  padding: 4px 8px !important;
}
.nav-item-lang .icon {
  margin-top: -6px;
  margin-right: 3px;
}
@media (min-width: 1025px) {
  .nav-item-lang .dropdown-menu {
    min-width: 100px;
    border-radius: 0;
    padding: 0;
    top: 70%;
    right: 0.5%;
    left: auto;
  }
}
.nav-item-lang .dropdown-item {
  padding: 5px 22px 7px;
}
.nav-item-lang .dropdown-item:hover, .nav-item-lang .dropdown-item:focus {
  background-color: #23ccf2;
  color: #fff;
}
@media (max-width: 810px) {
  .nav-item-lang {
    margin-left: 1em;
    margin-right: 1em;
    margin-top: 1em;
  }
  .nav-item-lang .nav-link {
    display: none;
  }
  .nav-item-lang .dropdown-menu {
    min-width: auto;
    width: 100%;
    border-radius: 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
  }
  .nav-item-lang .dropdown-menu li {
    flex: 1;
    text-align: center;
  }
}

.navbar {
  padding-top: 0;
  padding-bottom: 0;
}
@media (max-width: 1024px) {
  .navbar {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media (min-width: 900px) and (max-width: 1250px) {
  .navbar .container-fluid {
    flex-direction: column;
  }
}
@media (min-width: 1025px) {
  .navbar-collapse {
    position: static;
  }
}
@media (min-width: 900px) and (max-width: 1250px) {
  .navbar-collapse {
    width: 100%;
  }
}
@media (min-width: 900px) and (max-width: 1250px) {
  .navbar-nav {
    justify-content: space-between !important;
  }
}
@media (max-width: 1700px) {
  .navbar-brand {
    width: 22.5vw;
  }
}
@media (max-width: 1200px) {
  .navbar-brand {
    width: auto;
  }
}
@media (max-width: 810px) {
  .navbar-brand {
    width: 15vw;
  }
}
@media (max-width: 640px) {
  .navbar-brand {
    width: 70vw;
  }
}
.navbar-toggler {
  border: none;
  padding: 0;
  outline: none;
}
.navbar-toggler:focus {
  box-shadow: none;
}
.navbar-toggler .hamburger-toggle {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  z-index: 11;
}
.navbar-toggler .hamburger-toggle .hamburger {
  position: absolute;
  transform: translate(-50%, -50%) rotate(0deg);
  left: 50%;
  top: 50%;
  width: 50%;
  height: 50%;
  pointer-events: none;
}
.navbar-toggler .hamburger-toggle .hamburger span {
  width: 100%;
  height: 4px;
  position: absolute;
  background: #000;
  border-radius: 2px;
  z-index: 1;
  transition: transform 0.2s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.2s cubic-bezier(0.77, 0.2, 0.05, 1), all 0.2s ease-in-out;
  left: 0px;
}
.navbar-toggler .hamburger-toggle .hamburger span:first-child {
  top: 10%;
  transform-origin: 50% 50%;
  transform: translate(0% -50%) !important;
}
.navbar-toggler .hamburger-toggle .hamburger span:nth-child(2) {
  top: 50%;
  transform: translate(0, -50%);
}
.navbar-toggler .hamburger-toggle .hamburger span:last-child {
  left: 0;
  top: auto;
  bottom: 0;
  transform-origin: 50% 50%;
}
.navbar-toggler .hamburger-toggle .hamburger.active span {
  position: absolute;
  margin: 0;
}
.navbar-toggler .hamburger-toggle .hamburger.active span:first-child {
  top: 45%;
  transform: rotate(45deg);
}
.navbar-toggler .hamburger-toggle .hamburger.active span:nth-child(2) {
  left: 50%;
  width: 0px;
}
.navbar-toggler .hamburger-toggle .hamburger.active span:last-child {
  top: 45%;
  transform: rotate(-45deg);
}
.navbar-toggler.fixed {
  display: none;
  position: fixed;
  left: 62%;
  top: 2%;
  z-index: 1001;
}
.navbar-toggler.fixed .hamburger-toggle .hamburger span {
  background-color: #fff;
}
.navbar-toggler.fixed .hamburger-toggle .hamburger span:first-child {
  transform: rotate(40deg);
}
.navbar-toggler.fixed .hamburger-toggle .hamburger span:last-child {
  transform: translateY(-18px) rotate(-40deg);
}
.navbar-toggler.fixed.active {
  display: block;
}
@media (max-width: 810px) {
  .navbar-collapse {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: -100%;
    background: #fff;
    transition: 0.15s;
    overflow-y: auto;
    max-width: 60vw;
  }
  .navbar-collapse, .navbar-collapse > ul {
    z-index: 100;
  }
  .navbar-collapse > ul {
    position: relative;
    justify-content: flex-start !important;
    min-height: 100%;
  }
  .navbar-collapse.show {
    left: 0;
  }
}
.navbar-overlay {
  display: block;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
}
.navbar-overlay.active {
  opacity: 1;
  visibility: visible;
}
@media (min-width: 1025px) {
  .navbar-overlay {
    display: block;
  }
}
.navbar-mobile-icon {
  width: 80px;
  height: 40px;
  color: #000 !important;
  text-align: right;
  padding-right: 18px;
  position: absolute;
  right: 0;
  top: 5px;
}
.navbar-mobile-icon::after {
  display: none;
}

.dropdown-item {
  white-space: normal;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}

.icons {
  display: inline-flex;
  margin-left: auto;
}
.icons a {
  transition: all 0.2s ease-in-out;
  padding: 0.2rem 0.4rem;
  color: #ccc !important;
  text-decoration: none;
}
.icons a:hover {
  color: white;
  text-shadow: 0 0 30px white;
}

footer {
  color: #fff;
}
footer .wrapper {
  align-items: flex-start;
}
footer .footer-top, footer .footer-bottom {
  display: flex;
}
footer .footer-top {
  background-color: #403f3f;
  padding-top: 20px;
  padding-bottom: 15px;
}
@media (min-width: 1101px) and (max-width: 1600px) {
  footer .footer-top {
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 1em;
  }
}
@media (max-width: 1024px) {
  footer .footer-top {
    padding: 1em 0.25em;
  }
}
footer .footer-bottom {
  justify-content: flex-end;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 100;
  line-height: 1.5;
}
footer .footer-bottom p {
  margin-bottom: 0;
}
@media (max-width: 1024px) {
  footer .footer-bottom {
    font-size: 12px;
    font-size: 0.75rem;
    justify-content: center;
    text-align: center;
  }
  footer .footer-bottom-right {
    display: block;
  }
}
footer .footer-info {
  font-size: 0.875em;
  font-weight: 100;
  padding-left: 10px;
  padding-top: 7px;
}
footer .footer-info p {
  margin-bottom: 0.5em;
}
footer .footer-info-left {
  display: flex;
  align-items: flex-start;
}
@media (min-width: 1025px) {
  footer .footer-info-left {
    align-items: center;
  }
}
@media (max-width: 1024px) {
  footer .footer-info {
    padding-top: 10px;
  }
}
footer .footer-title {
  margin-bottom: 0.25em;
  font-weight: 900;
}
@media (max-width: 1024px) {
  footer .footer-title {
    font-size: 2em;
  }
}
footer .footer-txt {
  font-size: 17px;
  font-size: 1.0625rem;
}
@media (min-width: 1025px) {
  footer .footer-txt-list {
    padding-right: 37.5px;
  }
}
@media (max-width: 640px) {
  footer .footer-txt-list {
    display: block;
    position: relative;
    padding-left: 1.5em;
  }
  footer .footer-txt-list .icon {
    position: absolute;
    left: 0;
    top: 4px;
  }
}
footer .footer-icon {
  justify-content: flex-end;
  margin-top: 0.5em;
}
footer .footer-iconLink {
  margin-right: 5px;
}
footer .footer-logo {
  display: flex;
  align-items: center;
}
footer .footer-logo img {
  display: block;
  max-width: 100%;
}
footer .footer-info-wrap {
  width: 100%;
}
footer .footer-info-wrap li {
  flex: auto;
}
@media (min-width: 1025px) {
  footer .footer-info-wrap li {
    margin: 0 1em;
  }
}
@media (max-width: 1024px) {
  footer .footer-info-wrap {
    flex: auto;
    width: 100%;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  footer .footer-info-wrap li {
    flex: auto;
    width: 100%;
  }
  footer .footer-info-wrap li + li {
    margin-top: 0.5em;
  }
}
@media (max-width: 1024px) {
  footer .footer-info-bottom {
    margin-top: 1.5em !important;
  }
  footer .footer-info-bottom .footer-icon,
footer .footer-info-bottom .footer-info,
footer .footer-info-bottom .copyright p {
    justify-content: center;
  }
  footer .footer-info-bottom .copyright {
    flex-direction: column;
  }
}
@media (max-width: 1024px) {
  footer {
    margin-bottom: 2.25em;
  }
}

.divider {
  width: 2px;
  height: 100%;
  background: #bfb1a5;
}
@media (min-width: 800px) and (max-width: 1000px) {
  .divider {
    display: none;
  }
}

.iware {
  font-size: 87%;
  font-weight: 100;
  font-weight: lighter;
}
.iware,
.iware a {
  color: #fff;
}
.iware a:hover {
  text-decoration: underline;
}
.iware b {
  font-weight: 100;
}

.float-link {
  right: -100%;
  position: fixed;
  bottom: 200px;
  z-index: 10;
  transition: 0.3s;
}
.float-link .link-btn a:hover {
  opacity: 0.65;
}
@media (min-width: 1025px) {
  .float-link .link-btn.nabar-icon-mobile {
    display: none;
  }
}
.float-link .link-btn.nabar-icon-mobile img {
  max-width: 100%;
}
@media (max-width: 1024px) {
  .float-link .link-btn.top {
    display: none;
  }
}
.float-link.active {
  right: 5px;
}
@media (max-width: 1600px) {
  .float-link.active {
    right: 10px;
  }
}
@media (max-width: 1024px) {
  .float-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    bottom: 0;
    right: 0 !important;
    background: #23ccf2;
    padding: 5px;
    margin: 0;
  }
  .float-link .link-btn {
    text-align: center;
    margin: 0;
  }
  .float-link .link-btn,
.float-link .link-btn .link-btn-toggle {
    display: block;
    width: 100%;
  }
  .float-link .link-btn .link-btn-toggle {
    color: #fff;
  }
  .float-link .link-btn .icon small {
    font-size: 100%;
  }
}

.cookies {
  display: none;
  width: 100%;
  background: #303030;
  color: #fff;
  font-size: 17px;
  font-size: 1.0625rem;
  text-align: center;
  padding: 2rem 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 60;
}
.cookies p {
  font-weight: 400;
  line-height: 2;
  text-align: left;
  margin-bottom: 0;
}
.cookies .btn {
  text-decoration: none !important;
  border-radius: 6px;
  background-color: #02a3fd !important;
  border-color: #02a3fd;
}
@media (min-width: 800px) {
  .cookies .btn {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
@media (max-width: 640px) {
  .cookies .btn {
    display: block;
    width: 100%;
    font-size: 16px;
    font-size: 1rem;
    margin-top: 10px;
  }
}
.cookies-wrapper {
  align-items: flex-end;
  justify-content: space-around;
}
.cookies.display {
  display: block;
  animation: cookies 1s 1;
}
.cookies.close-cookies {
  transition: all 0.2s ease-out;
  transform: translate3d(0, 100%, 0);
}
@media (max-width: 810px) {
  .cookies.close-cookies {
    bottom: -2%;
  }
}
@media (min-width: 1200px) {
  .cookies .btn {
    margin-left: 2.5rem;
  }
}
@media (min-width: 1025px) {
  .cookies .btn {
    flex: 0 0 150px;
    font-size: 20px;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }
}
@media (max-width: 810px) {
  .cookies {
    width: calc(100% - 1em);
    border-top: none;
    margin: 0.5em;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 14px;
    font-size: 0.875rem;
  }
  .cookies.display {
    flex-direction: column;
  }
}

@keyframes cookies {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  100% {
    transform: translate3d(0, 0%, 0);
  }
}
.article,
article {
  width: 100%;
  font-size: 100%;
}
.article iframe,
article iframe {
  width: 100%;
  height: 750px;
}
@media (max-width: 810px) {
  .article iframe,
article iframe {
    height: 350px;
  }
}

.g-recaptcha iframe {
  height: auto !important;
}

.fw-900, .paper-title .title__underLine, .inMain-about-collapseItem .num, .inMain-about-collapse .accordion-button, .index-pageLink-title, .card-wrap-indexAct .btn, .card-btn-more, .card-time-date, .heading-title, .title-ch, .title-en, .nav-item-lang .nav-link {
  font-weight: 900 !important;
}

.boxShadow, .index-pageLink-wrap, .card-wrap-mainLink .card:hover .card-img-top, .card-wrap-mainNews .card:hover, .card-wrap-mainAct .card:hover, .card-wrap-indexAct .card, .card-wrap-indexNews .card {
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1) !important;
}

.bt-solid-c1 {
  border-top: 1px #ccc solid;
}

.unit {
  position: relative;
  display: block;
  line-height: 1.5;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}
.unit .btn {
  padding-top: 0.6em;
  padding-bottom: 0.6em;
}
.unit-icon {
  padding-left: 1.5em !important;
  padding-right: 0;
}
.unit-icon .icon {
  margin-top: 0;
  position: absolute;
  left: 0;
  top: -0.1rem;
}
@media (max-width: 1024px) {
  .unit {
    text-align: left;
    font-size: 16px;
  }
  .unit-icon .icon {
    top: 0.85rem;
  }
}

.select_style::after {
  content: "\f282";
  font-family: "bootstrap-icons" !important;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  position: absolute;
  right: 8px;
  top: 1px;
}
.select_style select.form-control {
  border: 1px solid #e6eaea !important;
  text-align: left;
  border-radius: 0;
  height: 40px !important;
  min-height: auto;
  padding-top: 0;
  padding-bottom: 0;
}
.select_style select {
  width: 100%;
  background: transparent;
  appearance: none;
  -webkit-appearance: none;
}
.select_style select,
.select_style input {
  border: none !important;
  height: 100% !important;
}
.select_style .form-control {
  border-radius: 0;
}
.select_style .disabled {
  background-color: #e9ecef;
}
.select_style .disabled select,
.select_style .disabled input {
  background: transparent;
}

.title-wrap {
  text-align: center;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
@media (max-width: 1024px) {
  .title-wrap {
    margin-bottom: 2px;
  }
}
.title-en {
  color: #d8d7d8;
  font-size: 30px;
  font-size: 1.875rem;
  font-family: "Noto Sans TC";
  text-transform: uppercase;
  margin-bottom: 0;
}
@media (max-width: 810px) {
  .title-en {
    font-size: 6.25vw;
  }
}
.title-ch {
  position: relative;
  font-size: 45px;
  font-size: 2.8125rem;
  color: #595757;
}
@media (max-width: 810px) {
  .title-ch {
    font-size: 4.375vw;
  }
}
@media (max-width: 640px) {
  .title-ch {
    font-size: 7.5vw;
  }
}
.title-left-circle {
  position: relative;
  padding-left: 15px;
  z-index: 1;
}
.title-left-circle::before {
  content: "";
  position: absolute;
  background-color: #04cafc;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  top: 50%;
  left: -15px;
  margin-top: -45px;
  z-index: -1;
}
.title-mainWrap {
  justify-content: space-between;
  padding-top: 0.25em;
}
@media (max-width: 1024px) {
  .title-mainWrap {
    padding-top: 0.65em;
    padding-bottom: 0.65em;
  }
}
.title__underLine {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #000;
}

.heading-title {
  font-size: 1.8421052632vw;
}
@media (max-width: 640px) {
  .heading-title {
    font-size: 25px;
    font-size: 1.5625rem;
  }
}
@media (min-width: 1025px) {
  .heading-title-wrap {
    flex-wrap: nowrap;
  }
}

.breadcrum-wrap .breadcrumb {
  font-size: 16px;
  font-size: 1rem;
  color: #404040;
  background-color: transparent;
  margin-bottom: 0;
  padding: 0.75rem;
  padding-right: 0;
}
.breadcrum-wrap .breadcrumb .breadcrumb-item {
  color: #777;
}
.breadcrum-wrap .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: "\f280";
  font-family: "bootstrap-icons" !important;
  padding-right: 0.65em;
}
.breadcrum-wrap .breadcrumb .breadcrumb-item.active {
  color: black;
}
@media (min-width: 1101px) and (max-width: 1350px) {
  .breadcrum-wrap .breadcrumb {
    padding-left: 3em;
  }
}
@media (max-width: 1024px) {
  .breadcrum-wrap .breadcrumb {
    padding-left: 0;
    padding-top: 0.3em;
    padding-bottom: 0;
  }
}
.breadcrum-wrap .icon {
  margin-top: -3px;
}

.pagination-wrap {
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.pagination-wrap .pagination {
  justify-content: center;
}
.pagination-wrap .pagination .page-link {
  width: 45px;
  height: 45px;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 45px;
  color: #6e6d6d;
  font-family: "Roboto";
  background-color: transparent;
  border-color: #7d7d7d;
  border-radius: 0;
  text-align: center;
  padding: 0;
  margin-right: 0.5rem;
}
.pagination-wrap .pagination .page-link:hover, .pagination-wrap .pagination .page-link.active {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.pagination-wrap .pagination-select .select_style {
  width: 100%;
  min-height: auto;
}
.pagination-wrap .pagination-select .select_style::after {
  top: 50%;
  transform: translateY(-50%);
}
@media (min-width: 1025px) {
  .pagination-wrap .pagination-select {
    width: 60px;
  }
}
@media (max-width: 1024px) {
  .pagination-wrap .pagination-select {
    display: flex;
    justify-content: center;
    width: 175px;
  }
}
@media (max-width: 1024px) {
  .pagination-wrap {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }
}

.text-main, .inMain-about-collapse .accordion-button, .inMain-about-collapse .accordion-button::after {
  color: #064583 !important;
}

.justify {
  text-align: justify;
}

.flex-none {
  flex: none !important;
}
@media (max-width: 640px) {
  .flex-none {
    width: 100%;
  }
}
.flex-auto {
  flex: auto !important;
}
.flex-1 {
  flex: 1 !important;
}

.tag-item, .tag-item-current, .tag-item-date {
  display: inline-block;
  width: auto;
  line-height: 1.1;
  margin: 2.5px;
  font-size: 14px;
  line-height: 1;
  padding: 6px;
  text-align: center;
  background-color: #eeeeee;
  color: #353535;
}
.tag-item-current, .tag-item-date {
  font-size: 18px;
  font-size: 1.125rem;
  color: #fff;
  padding: 8px 16px;
  border-radius: 6px;
}
.tag-item-current {
  background-color: #00b7ee;
}
.tag-item-date {
  background-color: #bfbf85;
}
.tag-item-wrap .tag-item ~ .tag-item, .tag-item-wrap .tag-item-current ~ .tag-item, .tag-item-wrap .tag-item-date ~ .tag-item, .tag-item-wrap .tag-item ~ .tag-item-current, .tag-item-wrap .tag-item-current ~ .tag-item-current, .tag-item-wrap .tag-item-date ~ .tag-item-current, .tag-item-wrap .tag-item ~ .tag-item-date, .tag-item-wrap .tag-item-current ~ .tag-item-date, .tag-item-wrap .tag-item-date ~ .tag-item-date {
  margin-left: 5px;
}
@media (min-width: 1025px) {
  .tag-item-wrap {
    padding-left: 15px;
  }
}
@media (max-width: 1024px) {
  .tag-item-wrap {
    padding-top: 10px;
  }
}

.form-control,
.btn {
  border-radius: 0.5em;
}

.form-control {
  height: auto;
  min-height: 50px;
}
.modal {
  text-align: center;
  padding-right: 0 !important;
}
.modal-content {
  border-color: #accccd;
  border-width: 3px;
}
.modal-open {
  padding-right: 0 !important;
}
@media (min-width: 1025px) {
  .modal {
    text-align: center;
    padding: 0 !important;
  }
  .modal:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
  }
  .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }
}
@media (min-width: 500px) {
  .modal-dialog {
    width: 100%;
    max-width: 85rem;
  }
}
@media (min-width: 1100px) and (max-width: 1500px) {
  .modal-dialog {
    max-width: 70rem;
  }
}
.modal-autoheight .modal-body {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 100px !important;
  max-height: 100vh !important;
}
@media (min-width: 500px) {
  .modal-autoheight .modal-body {
    max-height: calc(100vh - 50px) !important;
  }
}
@media (max-width: 1080px) {
  .modal-autoheight .modal-body {
    height: calc(90vh - 1.5rem) !important;
  }
}
@media (max-width: 1080px) {
  .modal-autoheight {
    transform: translateY(5%);
  }
}

.btn {
  font-size: 20px;
  font-size: 1.25rem;
  padding: 0.5em 1.25em;
  border-radius: 0px;
  font-weight: 100;
}
.btn .fa {
  font-weight: 100;
  vertical-align: middle;
  margin-top: -3px;
  margin-left: 6px;
}
.btn .icon {
  vertical-align: top;
  margin-top: -2px;
  margin-left: 3px;
  margin-right: 3px;
}
.btn-primary {
  background-color: #02a3fd;
  border-color: #02a3fd;
}
.btn-icon {
  position: relative;
  border-radius: 0.3rem;
  padding-right: 50px !important;
}
.btn-icon .icon {
  position: absolute;
  top: 50%;
  right: 13px;
  transform: translateY(-50%);
}
.btn-icon-left {
  padding-right: 30px !important;
  padding-left: 60px !important;
}
.btn-icon-left .icon {
  left: 20px;
  right: auto;
}
.btn-wrap {
  justify-content: center;
  width: 100%;
  margin: 2rem 0 1rem;
}
.btn-wrap > li {
  flex: 1;
}
.btn-wrap > li + li {
  margin-left: 1.5rem;
}
@media (min-width: 1025px) {
  .btn-wrap {
    flex-wrap: nowrap;
  }
}
@media (max-width: 1080px) {
  .btn-wrap {
    margin-top: 1rem;
  }
  .btn-wrap > li + li {
    margin-left: 0.5rem;
  }
}
@media (max-width: 640px) {
  .btn-wrap > li {
    flex: none;
    width: 100%;
  }
  .btn-wrap > li + li {
    margin-top: 1em;
  }
}

.js-toggle-btn .btn {
  font-size: 16px;
  font-size: 1rem;
  border-radius: 20px;
  border: #eee 1px solid;
}
.js-toggle-btn .btn i {
  color: currentColor;
}
.js-toggle-btn .btn .icon {
  margin-top: 0;
}

.box {
  align-items: stretch;
  position: relative;
  z-index: 1;
  font-size: 18px;
  font-size: 1.125rem;
  padding: 10px;
}
.box p {
  margin-bottom: 0;
}
.box-head {
  flex: none;
  color: #717171;
  padding-left: 10px;
}
.box-text {
  flex: 1;
}

.card {
  flex-grow: 1;
  margin-left: 1em;
  margin-right: 1em;
  margin-bottom: 2em;
  border: none;
  text-align: left;
  border-radius: 0;
}
@media (min-width: 1025px) {
  .card__2 {
    flex: 0 0 calc(50% - 2em);
    max-width: calc(50% - 2em);
  }
}
@media (min-width: 1025px) {
  .card__3 {
    flex: 0 0 calc(33.3333333333% - 2em);
    max-width: calc(33.3333333333% - 2em);
  }
}
@media (min-width: 1025px) {
  .card__4 {
    flex: 0 0 calc(25% - 2em);
    max-width: calc(25% - 2em);
  }
}
@media (max-width: 1024px) {
  .card {
    margin-left: 0.5em;
    margin-right: 0.5em;
    flex: 0 0 calc(50% - 1em);
    max-width: calc(50% - 1em);
  }
}
.card__full {
  flex: 0 0 calc(100% - 2em) !important;
  max-width: calc(100% - 2em) !important;
}
@media (min-width: 1025px) {
  .card__horizontal {
    flex-direction: row;
  }
}
.card-img-top {
  border-radius: 0px;
}
.card-img-top img {
  display: block;
  width: 100%;
}
.card-img-top + .card-caption {
  margin-top: 10px;
}
.card-time-date {
  font-size: 24px;
  font-size: 1.5rem;
}
@media (max-width: 1024px) {
  .card-time-date {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
.card-time-year {
  font-size: 16px;
  font-size: 1rem;
  color: #8b8b8b;
}
@media (min-width: 1025px) {
  .card-time {
    margin-bottom: 1em;
  }
}
.card-title {
  font-size: 23px;
  font-size: 1.4375rem;
  font-weight: 900;
  line-height: 1.5;
}
.card-caption {
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
}
.card-body {
  padding: 1.25em;
}
.card-text {
  font-size: 16px;
  font-size: 1rem;
  color: #5f5f5f;
  font-weight: 100;
}
.card-btn {
  width: 100%;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 900;
  background-color: #c9c9c9 !important;
  color: #fff !important;
  margin-bottom: 1em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}
.card-btn:hover {
  background-color: #23ccf2 !important;
}
.card-btn-more {
  font-size: 14px;
  font-size: 0.875rem;
  border-bottom: #333 1px solid;
  padding-bottom: 2px;
}
@media (max-width: 640px) {
  .card-btn {
    padding-left: 0;
    padding-right: 0;
  }
}
.card-wrap {
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
}
@media (min-width: 1025px) {
  .card-wrap-indexNews .card-title {
    margin-bottom: 1em;
  }
}
@media (min-width: 1025px) {
  .card-wrap-indexNews .card-body {
    display: flex;
    flex-direction: column;
    padding-left: 3em;
    padding-right: 3em;
  }
  .card-wrap-indexNews .card-body .btn-wrap {
    margin-top: auto;
  }
}
@media (max-width: 1024px) {
  .card-wrap-indexNews .card-body {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.card-wrap-indexNews .card-text {
  min-height: 50px;
  margin-bottom: 1.5em;
}
@media (max-width: 1024px) {
  .card-wrap-indexNews .card-text {
    height: 100%;
  }
}
@media (max-width: 1024px) {
  .card-wrap-indexNews .card {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    margin-left: 0;
    margin-right: 0;
  }
}
@media (min-width: 1025px) {
  .card-wrap-indexNews {
    margin-top: 2em;
    margin-bottom: 2em;
  }
}
.card-wrap-indexAct {
  margin-top: 2em;
}
.card-wrap-indexAct .card-title, .card-wrap-indexAct .card-time {
  padding-left: 0.5em;
  -webkit-text-stroke: 0;
}
.card-wrap-indexAct .card-time {
  font-size: 14px;
  font-size: 0.875rem;
  color: #686868;
  font-weight: 100;
  margin-top: 10px;
  margin-bottom: 5px;
}
.card-wrap-indexAct .card-time .icon {
  margin-right: 5px;
}
.card-wrap-indexAct .card-img-top {
  border-radius: 30px;
  overflow: hidden;
}
.card-wrap-indexAct .card-title {
  font-size: 20px;
  font-size: 1.25rem;
  color: #3f403d;
  padding-left: 0.75em;
  margin-top: 0.75em;
  margin-bottom: 1em;
}
@media (max-width: 1024px) {
  .card-wrap-indexAct .card-title {
    margin-bottom: 0;
  }
  .card-wrap-indexAct .card-title::before {
    left: -7.5px;
  }
}
@media (min-width: 1025px) {
  .card-wrap-indexAct .card-body {
    padding-left: 2em;
    padding-right: 2em;
  }
}
@media (max-width: 1024px) {
  .card-wrap-indexAct .card {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    margin-left: 0;
    margin-right: 0;
  }
}
.card-wrap-indexAct .btn {
  padding-left: 0;
}
.card-wrap-mainNews .card, .card-wrap-mainAct .card {
  background-color: #f5f4f4;
  box-shadow: none !important;
}
.card-wrap-mainNews .card:hover, .card-wrap-mainAct .card:hover {
  background-color: #fff;
}
.card-wrap-mainLink .card:hover .card-img-top {
  background-color: #fff;
}
@media (min-width: 1025px) {
  .card-wrap-mainLink .card {
    background-color: transparent;
    box-shadow: none !important;
    margin-bottom: 3em;
  }
  .card-wrap-mainLink .card:hover .card-title {
    font-weight: 600;
    text-decoration: underline;
  }
}
@media (max-width: 1024px) {
  .card-wrap-mainLink .card {
    padding-top: 0.5em;
  }
  .card-wrap-mainLink .card:hover .card-img-top {
    box-shadow: none !important;
  }
}
.card-wrap-mainLink .card-img-top {
  text-align: center;
  padding: 1.5em 1em 25%;
}
.card-wrap-mainLink .card-img-top img {
  width: auto;
}
@media (max-width: 1024px) {
  .card-wrap-mainLink .card-img-top {
    padding-bottom: 42.5%;
  }
}
@media (max-width: 1024px) {
  .card-wrap-mainLink .card-body {
    padding-top: 0;
  }
}
.card-wrap-mainLink .card-title {
  font-weight: 100;
}
@media (max-width: 640px) {
  .card-wrap-mainLink .card-title {
    font-size: 16px;
    font-size: 1rem;
    text-align: center;
    margin-top: 0;
  }
}
.card-wrap-mainAct .card:hover {
  border: #23ccf2 1px solid;
  box-shadow: none !important;
}
.card:hover .card-btn {
  color: #252525;
}
.card:hover .card-btn::after {
  background-color: #252525;
}
@media (max-width: 1024px) {
  .card {
    margin-bottom: 1em;
  }
  .card-title {
    font-size: 15px;
    font-size: 0.9375rem;
    margin-top: 0.25em;
  }
  .card-body {
    padding: 0.75em;
  }
  .card-caption {
    padding-top: 0.125em;
    padding-bottom: 0.25em;
  }
}

.news .card-caption {
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 1025px) {
  .news .card {
    margin-left: 1.5em;
    margin-right: 1.5em;
    flex: 0 0 calc(33.3333333333% - 3em);
    max-width: calc(33.3333333333% - 3em);
  }
  .news .card-img-top {
    margin-bottom: 1.5em;
  }
  .news .card-btn {
    margin-top: 1.25em;
  }
  .news .card__horizontal {
    background: #14aa6b; /* Old browsers */
    background: -moz-linear-gradient(65deg, #14aa6b 1%, #14aa6b 30%, #14aa6b 27%, #f5f5f5 27%, #f5f5f5 27%, #f5f5f5 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(65deg, #14aa6b 1%, #14aa6b 30%, #14aa6b 27%, #f5f5f5 27%, #f5f5f5 27%, #f5f5f5 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(65deg, #14aa6b 1%, #14aa6b 30%, #14aa6b 27%, #f5f5f5 27%, #f5f5f5 27%, #f5f5f5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#14aa6b", endColorstr="#f5f5f5",GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    padding: 25px;
    margin-bottom: 50px;
  }
  .news .card__horizontal .card-img-top {
    flex: none;
    width: 510px;
    margin-right: 50px;
    margin-bottom: 0;
  }
  .news .card__horizontal .card-caption {
    padding-left: 0;
  }
  .news .card__horizontal .card-body {
    display: flex;
    flex-direction: column;
  }
  .news .card__horizontal .card-body .card-btn {
    margin-top: auto;
  }
}
.news-content .card {
  max-width: 100%;
  margin-bottom: 10px;
}
.news-link {
  border-top: 2px solid #000;
  border-bottom: 1px solid #666;
}
.news-link .box {
  background-color: #f4f4f4;
  padding: 15px;
}
.news-link .box ~ .box {
  border-top: 1px solid #666;
}
.news-link .box-text .icon {
  margin-top: -9px;
  margin-left: 3px;
}
@media (min-width: 1025px) {
  .news-link .box-head {
    position: relative;
  }
  .news-link .box-head::after {
    content: "";
    width: 1px;
    height: 17px;
    background: #9a9999;
    position: absolute;
    top: 50%;
    right: -17px;
    transform: translate(50%, -50%);
  }
  .news-link .box-text {
    padding-left: 40px;
  }
}
@media (max-width: 1024px) {
  .news-link .box {
    flex-direction: column;
  }
  .news-link .box-head {
    padding-left: 0;
  }
  .news-link .box-text {
    width: 100%;
    padding-top: 5px;
    word-break: break-all;
  }
}
.news-article {
  margin-top: 3em;
  padding: 0.5em;
}
@media (min-width: 1025px) {
  .news-article {
    padding: 3em;
  }
}

.activity_Swiper {
  padding: 0 4em;
}
.activity_Swiper .swiper-button-next, .activity_Swiper .swiper-button-prev {
  color: #000;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: #d0d0d0 1px solid;
}
.activity_Swiper .swiper-button-next::after, .activity_Swiper .swiper-button-prev::after {
  font-size: 1.5rem;
}
.activity_Swiper .swiper-button-next {
  right: 0;
}
.activity_Swiper .swiper-button-prev {
  left: 0;
}
.activity_Swiper .swiper-slide-img {
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 65%;
}
.activity_Swiper .swiper-slide-img img {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  max-height: 100%;
  margin: auto;
}

@media (min-width: 1025px) {
  .form-inMain-wrap {
    align-items: flex-end;
    padding-left: 70px;
    padding-right: 70px;
  }
}
.form-wrap {
  background-color: #fff;
  border: #eee 1px solid;
  padding: 1.5em;
}
.form-wrap legend {
  font-size: 16px;
  font-size: 1rem;
  margin-bottom: 2.5em;
}
.form-wrap .form-control {
  border-radius: 0;
  border-width: 0 0 1px 0px;
}
@media (min-width: 1025px) {
  .form-wrap {
    padding: 50px;
  }
}
.form-flex {
  display: flex;
  margin-bottom: 25px;
}
.form-flex label {
  flex: none;
  width: 80px;
}
.form-check {
  padding-left: 0;
}

.index-content {
  padding-top: 1.5em;
  padding-bottom: 2em;
}
@media (max-width: 1400px) {
  .index-content {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
}
@media (max-width: 1024px) {
  .index-content {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}
.index-pageNews {
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  background-image: url("../images/index-pageNews.jpg");
  padding-top: 20px;
  margin-top: -6em;
}
@media (max-width: 1024px) {
  .index-pageNews {
    margin-top: -2em;
  }
}
.index-pageAct {
  background-repeat: no-repeat;
  background-position: left top;
  background-size: contain;
  background-image: url("../images/index-pageAct.jpg");
  background-color: #deeefb;
}
@media (min-width: 1025px) {
  .index-pageAct {
    padding-top: 2em;
  }
}
.index-pageAct .title-wrap {
  display: inline-block;
  margin-left: 1.5em;
  margin-top: 1.5em;
}
.index-pageAct .title-ch {
  color: #fff;
}
@media (min-width: 1025px) {
  .index-pageAct .btn-wrap-indexAct {
    justify-content: flex-end;
    position: absolute;
    right: 15px;
    top: 47px;
  }
}
.index-pageAct .btn-outline-primary {
  border-color: #064583;
  color: #064583;
}
.index-pageAct .btn-outline-primary:hover {
  color: #fff;
}
.index-pageLink {
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  background-image: url("../images/index-pageLink.jpg");
}
.index-pageLink-swiper {
  overflow: hidden;
}
.index-pageLink .swiper-container {
  padding-top: 1.25em;
  padding-bottom: 1.25em;
  overflow: hidden;
}
.index-pageLink .swiper-wrapper {
  /*通过改变animation-timing-function 制作弹性切换效果*/
  transition: 2s cubic-bezier(0.68, -0.4, 0.27, 1.34) 0.2s;
}
@media (max-width: 1024px) {
  .index-pageLink .swiper-slide {
    padding: 1em;
  }
}
.index-pageLink .swiper-button-next, .index-pageLink .swiper-button-prev {
  color: #000;
}
@media (max-width: 1024px) {
  .index-pageLink .swiper-button-next::after, .index-pageLink .swiper-button-prev::after {
    font-size: 30px;
    font-size: 1.875rem;
  }
  .index-pageLink .swiper-button-next {
    right: 0;
  }
  .index-pageLink .swiper-button-prev {
    left: 0;
  }
}
.index-pageLink-wrap {
  width: 100%;
  max-width: 1100px;
  margin: auto;
  background-color: #fff;
  padding: 1.5em 3em;
}
@media (max-width: 1024px) {
  .index-pageLink-wrap {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
}
.index-pageLink-title {
  display: inline-block;
  color: #cb3d2e;
  font-size: 20px;
  font-size: 1.25rem;
  border-bottom: #cb3d2e 1px solid;
  margin-bottom: 10px;
  padding-bottom: 5px;
}
.index-pageLink-txt {
  color: #6c6c6b;
  font-size: 18px;
  font-size: 1.125rem;
}
@media (max-width: 1024px) {
  .index-pageLink-title {
    font-size: 14px;
    font-size: 0.875rem;
  }
  .index-pageLink-txt {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}

.page-banner {
  width: 100%;
  overflow: hidden;
  z-index: 1;
}
@media (min-width: 900px) and (max-width: 1100px) {
  .page-banner {
    margin-top: 1%;
  }
}
@media (min-width: 1025px) {
  .page-banner .bannerSwiper {
    padding-bottom: 3em;
  }
  .page-banner .bannerSwiper::before {
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
  }
}
.page-banner .swiper-pagination-bullets {
  bottom: 0 !important;
}
@media (min-width: 1025px) {
  .page-banner .swiper-pagination-bullets {
    width: auto !important;
    left: auto !important;
    bottom: auto !important;
    right: 1.5em;
    top: 50%;
    transform: translate3d(0px, -50%, 0);
    border: #fff 1px solid;
    padding: 0.5em;
    border-radius: 1em;
  }
}
@media (max-width: 1024px) {
  .page-banner .swiper-pagination-bullets {
    bottom: -1.5em !important;
  }
}
.page-banner .swiper-pagination-bullet {
  width: 12px !important;
  height: 12px !important;
  background-color: #000;
}
.page-banner .swiper-pagination-bullet-active {
  background-color: #23ccf2 !important;
  border-radius: 0.75em !important;
}
@media (min-width: 1025px) {
  .page-banner .swiper-pagination-bullet {
    display: block;
    margin: 0.3em 0 !important;
    transition: height 200ms ease-in;
    background-color: #fff;
    opacity: 1;
  }
  .page-banner .swiper-pagination-bullet-active {
    height: 1.75em !important;
  }
}
@media (max-width: 1024px) {
  .page-banner .swiper-pagination-bullet-active {
    width: 1.75em !important;
    margin: 0 0.3em !important;
  }
}
@media (max-width: 640px) {
  .page-banner-main img {
    height: 100px;
    object-fit: cover;
  }
}

.animationItem {
  transform: translate3d(-50px, 0, 0);
  opacity: 0;
  transition: 0.85s cubic-bezier(0.31, 1.26, 0.19, 1.11);
}

.animationItem.animated {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.inMain-about .article {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.8;
}
.inMain-about-avator-left, .inMain-about-avator-right {
  border-radius: 10px;
  padding: 0.95em;
  overflow: hidden;
  display: flex;
}
@media (min-width: 800px) {
  .inMain-about-avator-left, .inMain-about-avator-right {
    align-items: center;
  }
}
@media (max-width: 640px) {
  .inMain-about-avator-left, .inMain-about-avator-right {
    margin-bottom: 1em;
  }
  .inMain-about-avator-left img, .inMain-about-avator-right img {
    width: 100px;
  }
}
.inMain-about-avator-left {
  background-color: #dae7f0;
}
.inMain-about-avator-right {
  background-color: #dceeec;
}
.inMain-about-menu {
  display: grid;
  grid-auto-flow: column;
  margin-top: 30px;
  margin-bottom: 30px;
}
.inMain-about-menu li {
  position: relative;
  color: #717171;
  line-height: 1.25;
}
.inMain-about-menu li:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 30px;
  width: 1px;
  height: 100%;
  background: #636363;
  transform: translate(50%, -50%);
}
@media (min-width: 820px) {
  .inMain-about-menu {
    grid-template-columns: 350px 350px 350px 350px;
  }
  .inMain-about-menu li:not(:last-child)::after {
    right: 31px;
  }
}
@media (max-width: 810px) {
  .inMain-about-menu {
    grid-auto-flow: row;
  }
}
.inMain-about-collapse .accordion-item {
  border: none;
  margin-bottom: 1em;
}
.inMain-about-collapse .accordion-button {
  font-size: 18px;
  font-size: 1.125rem;
  background: #f5f5f5;
  padding: 1em 1.25em;
}
.inMain-about-collapse .accordion-button::after {
  content: "\f64d";
  font-family: "bootstrap-icons" !important;
  background-image: none;
}
.inMain-about-collapse .accordion-button:not(.collapsed)::after {
  content: "\f63b";
}
@media (min-width: 1025px) {
  .inMain-about-collapse .accordion-button,
.inMain-about-collapse .accordion-body {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
.inMain-about-collapseItem {
  align-items: stretch;
  padding: 0.875em 0;
  position: relative;
  z-index: 1;
  border-bottom: 1px solid #d9d9d9;
}
.inMain-about-collapseItem p {
  margin-bottom: 0;
}
.inMain-about-collapseItem .num {
  margin-top: -3px;
}
@media (min-width: 1025px) {
  .inMain-about-collapseItem .num {
    font-size: 22px;
    font-size: 1.375rem;
  }
}
@media (min-width: 1025px) {
  .inMain-about-collapseItem {
    flex-wrap: nowrap;
    padding: 20px 10px;
  }
  .inMain-about-collapseItem .num {
    flex: none;
  }
  .inMain-about-collapseItem,
.inMain-about-collapseItem .text {
    padding-left: 50px;
  }
}
.inMain-about-supervisorList {
  border: #D8D8D8 3px solid;
  margin-top: 1.25em;
  margin-bottom: 2.5em;
  padding-bottom: 5px;
}
.inMain-about-supervisorList,
.inMain-about-supervisorList .box {
  padding: 10px;
}
.inMain-about-supervisorList .box {
  background-color: #fff;
}
.inMain-about-supervisorList .box:nth-child(odd) {
  background-color: #F4F4F4;
}
.inMain-about-supervisorList .box .unit {
  display: inline;
  padding-right: 0;
}
@media (min-width: 1025px) {
  .inMain-about-supervisorList .box-head {
    min-width: 125px;
    padding-left: 15px;
  }
}
@media (max-width: 1024px) {
  .inMain-about-supervisorList .box-head {
    min-width: 100%;
  }
}
.inMain-about-supervisorList.current {
  border-color: #02a3fd;
}
.inMain-about-supervisorList.current .box:nth-child(odd) {
  background-color: #EEF3F7;
}

@media (min-width: 1025px) {
  .form-inMain-wrap {
    align-items: flex-end;
    padding-left: 70px;
    padding-right: 70px;
  }
}
.form-wrap {
  background-color: #fff;
  border: #eee 1px solid;
  padding: 1.5em;
}
.form-wrap legend {
  font-size: 16px;
  font-size: 1rem;
  margin-bottom: 2.5em;
}
.form-wrap .form-control {
  border-radius: 0;
  border-width: 0 0 1px 0px;
}
@media (min-width: 1025px) {
  .form-wrap {
    padding: 50px;
  }
}
.form-flex {
  display: flex;
  margin-bottom: 25px;
}
.form-flex label {
  flex: none;
  width: 80px;
}
.form-check {
  padding-left: 0;
}

.dowload-fileName, .dowload-act {
  text-align: left;
}
.dowload-num {
  padding-right: 5px;
}
@media (min-width: 1025px) {
  .dowload-num {
    flex: 0 0 65px;
  }
}
.dowload-fileName {
  flex: 1;
  padding-left: 1rem;
}
.dowload-act .btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 18px;
  font-size: 1.125rem;
  padding-left: 1em;
  padding-right: 1em;
  border-color: #a0a0a0;
}
.dowload-act .btn::after {
  content: "";
  display: block;
  width: 23px;
  height: 23px;
  background-image: url("../images/icon-download-gray.png");
}
.dowload-act .btn:hover {
  background-color: #23ccf2;
  border-color: #23ccf2;
  color: #fff;
}
.dowload-act .btn:hover::after {
  background-image: url("../images/icon-download-w.png");
}
@media (min-width: 1025px) {
  .dowload-act {
    flex: 0 0 300px;
  }
}
.dowload-wrap {
  width: 100%;
  margin-bottom: 1rem;
}
.dowload-wrap p {
  margin-bottom: 0;
}
.dowload-wrap .btn {
  display: block;
  width: 100%;
  text-align: left;
  padding: 0.5rem 0.75rem;
  padding-right: 2rem;
  color: #8c8b8b;
  border-color: #8c8b8b;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px);
  background-size: auto;
  background-image: url("../images/icon-download-g.png");
  font-size: 16px;
  font-size: 1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.dowload-wrap .btn, .dowload-wrap .btn:hover {
  background-color: #fff;
}
.dowload-wrap .btn:hover {
  color: #000;
  border-color: #23ccf2;
  background-image: url("../images/icon-download-b.png");
}
.dowload-item, .dowload-list {
  padding-bottom: 20px;
  text-align: center;
}
.dowload-item {
  font-size: 18px;
  font-size: 1.125rem;
  color: #696969;
  border-bottom: 1px solid #434343;
  justify-content: space-between;
  padding-top: 10px;
}
@media (max-width: 640px) {
  .dowload-item {
    display: none;
  }
}
.dowload-list {
  font-size: 20px;
  font-size: 1.25rem;
  color: #646464;
  line-height: 1.5;
}
.dowload-list li {
  justify-content: space-between;
  border-bottom: 2px solid #e1e1e1;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.dowload-list li .dowload-num {
  color: #747474;
}
@media (max-width: 640px) {
  .dowload-list {
    padding-left: 5px;
    padding-right: 5px;
  }
  .dowload-list li {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .dowload-list li .dowload-fileName {
    padding-left: 0;
  }
  .dowload-list li > div {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
}

.paper-yearSelect {
  display: flex;
  align-items: center;
}
.paper-yearSelect .unit {
  flex: none;
  padding-right: 15px;
}
.paper-yearSelect .select_style {
  width: 100%;
  max-width: 300px;
}
.paper-yearSelect .select_style::after {
  right: 11px;
  top: 50%;
  transform: translateY(-50%);
}
.paper-yearSelect .select_style .form-control {
  height: 50px !important;
}
.paper-title {
  margin-top: 2em;
  margin-bottom: 2em;
}
.paper-title .title__underLine {
  padding-bottom: 20px;
}
@media (min-width: 1025px) {
  .paper-title {
    margin-top: 4em;
  }
}
.paper-list .box {
  border: #BBBBBB 1px solid;
  margin-top: 10px;
  margin-bottom: 25px;
  padding: 20px;
}
@media (max-width: 640px) {
  .paper-list .box {
    padding: 10px;
  }
  .paper-list .box img {
    max-width: 100px;
  }
}
.paper-btn .btn {
  background-color: #F4F4F4;
  margin-right: 10px;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  border-radius: 0;
}
.paper-btn .btn:hover {
  background-color: #000;
}
@media (max-width: 640px) {
  .paper-btn .btn {
    width: 100%;
    padding-left: 0.5em !important;
  }
  .paper-btn .btn ~ .btn {
    margin-top: 10px;
  }
}

.contact-bg {
  background-repeat: no-repeat;
  background-position: top left;
  background-size: auto;
  background-image: url("../images/contact_bgInfo.png");
}
@media (min-width: 1025px) {
  .contact-bg {
    padding-bottom: 120px;
  }
}
@media (min-width: 1251px) and (max-width: 1450px) {
  .contact-bg {
    padding-bottom: 140px;
  }
}
@media (max-width: 1080px) {
  .contact-bg {
    background-size: 100%;
    padding-bottom: 0;
  }
}
.contact-wrapper {
  position: relative;
}
@media (min-width: 1025px) {
  .contact-wrapper {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}
.contact-info {
  flex: 1;
  margin-top: 10px;
}
.contact-info-txt {
  width: 100%;
  border: #777 1px solid;
  background-color: #fff;
  padding: 1.75em 2.25em 3em;
  margin-bottom: 1em;
}
@media (max-width: 1024px) {
  .contact-info-txt {
    padding: 1em;
  }
}
.contact-form {
  width: 100%;
  background: #fff;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.contact-form legend {
  color: #222222;
  line-height: 1.5;
  font-size: 22px;
  font-size: 1.375rem;
  margin-bottom: 20px;
}
@media (min-width: 1025px) {
  .contact-form legend {
    padding-left: 2em;
  }
}
.contact-form label {
  color: #777;
  margin-bottom: 0;
}
.contact-form .form-control, .contact-form .form-control:hover, .contact-form .form-control:focus {
  border: none;
  outline: none;
  box-shadow: none;
  background-color: #fff;
  border-radius: 0;
}
.contact-form .form-control:hover, .contact-form .form-control:focus {
  border-color: #000;
}
.contact-form .form-control.form-border {
  border: #c9c9c9 1px solid;
  border-radius: 6px;
}
@media (max-width: 1024px) {
  .contact-form .form-control.form-border {
    width: 100% !important;
    flex: 1 !important;
  }
}
.contact-form .form-group {
  display: flex;
  border-bottom: 1px solid #ddd;
  margin-bottom: 5px;
}
.contact-form .form-group label {
  flex: none;
  width: 90px;
  padding-left: 10px;
}
@media (max-width: 1080px) {
  .contact-form .form-group {
    flex-direction: column;
    margin-bottom: 0.5em;
  }
}
.contact-form .btn-wrap li {
  display: flex;
  justify-content: center;
  width: 100%;
}
@media (min-width: 1025px) {
  .contact-col-left {
    padding-right: 40px;
  }
}