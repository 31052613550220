.form{
    &-inMain-wrap{
        @include setMinRwd{
            align-items: flex-end;
            padding-left: 70px;
            padding-right: 70px;
        }
    }

    &-wrap{
        background-color: #fff;
        border: #eee 1px solid;
        padding: 1.5em;

        legend{
            @include fontSize(16px);
            margin-bottom: 2.5em;
        }

        .form-control {
            border-radius: 0;
            border-width: 0 0 1px 0px;
        }

        @include setMinRwd{
            padding: 50px;
        }
    }

    &-flex{
        display: flex;
        margin-bottom: 25px;
        label{
            flex: none;
            width: 80px;
        }
    }
    &-check {
        padding-left: 0;
    }
}