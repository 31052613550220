$card-left: 1em;
$card-right: 1em;
$card-bottom: 2em;

$mobile-card-left: 0.5em;
$mobile-card-right: 0.5em;

$card-btn :#aaaaaa;
$card-btn-active :#252525;




.box{
    @extend %setFlex;
    align-items: stretch;
    position: relative;
    z-index: 1;
    @include fontSize(18px);
    padding: 10px;

    p{
        margin-bottom:0;
    }
    
    &-head{
        flex: none;
        color: #717171;
        padding-left: 10px;
    }

    &-text{
        flex: 1;
    }
}

.card{
    flex-grow: 1;
    margin-left: $card-left;
    margin-right: $card-right;
    margin-bottom: $card-bottom;
    border: none;
    text-align: left;
    @include radius(0);

    // Grid
    &__2{
        @include setMinRwd{
            flex: 0 0 calc(100% / 2 - ($card-left + $card-right));
            max-width: calc(100% / 2 - ($card-left + $card-right));
        }
    }

    &__3{
        @include setMinRwd{
            flex: 0 0 calc(100% / 3 - ($card-left + $card-right));
            max-width: calc(100% / 3 - ($card-left + $card-right));
        }
    }

    &__4{
        @include setMinRwd{
            flex: 0 0 calc(100% / 4 - ($card-left + $card-right));
            max-width: calc(100% / 4 - ($card-left + $card-right));
        }
    }

    @include setMaxRwd{
        margin-left: $mobile-card-left;
        margin-right: $mobile-card-right;
        flex: 0 0 calc(100% / 2 - ($mobile-card-left + $mobile-card-right));
        max-width: calc(100% / 2 - ($mobile-card-left + $mobile-card-right));
    }

    &__full{
        flex: 0 0 calc(100% - ($card-left + $card-right)) !important;
        max-width: calc(100% - ($card-left + $card-right)) !important;
    }

    &__horizontal{
        @include setMinRwd{
            flex-direction: row;
        }
    }
    // End

    &-img-top{
        @include radius(0px);

        img{
            display: block;
            width: 100%;
        }

        & + .card-caption{
            margin-top: 10px;
        }
    }

    &-time{
        @extend %setFlex-center;

        &-date{
            @include fontSize(24px);
            @extend .fw-900;
            
            @include setMaxRwd{
                @include fontSize(20px);
            }
        }

        &-year{
            @include fontSize(16px);
            color: #8b8b8b;
        }

        @include setMinRwd{
            margin-bottom: 1em;
        }
    }

    &-title{
        @include fontSize(23px);
        font-weight: 900;
        line-height: 1.5;
    }

    &-caption{
        padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
    }

    &-body{
        padding: 1.25em;
    }

    &-text{
        @include fontSize(16px);
        color: $colorSubText;
        font-weight: 100;
    }

    &-btn{
        width: 100%;
        @include fontSize(16px);
        font-weight: 900;
        background-color: #c9c9c9 !important;
        color: #fff !important;
        margin-bottom: 1em;
        padding-top: 0.75em;
        padding-bottom: 0.75em;

        &:hover{
            background-color: $colorMain !important
        }

        &-more{
            @include fontSize(14px);
            border-bottom: #333 1px solid;
            padding-bottom: 2px;
            @extend .fw-900;
        }

        @include mobile{
            padding-left: 0;
            padding-right: 0;
        }
    }

    // 框
    &-wrap{
        @extend %setFlex;
        width: 100%;
        margin-top: 1em;
        margin-bottom: 1em;

        &-index{
            &News{
                .card{
                    @extend .boxShadow;
                    &-title{
                        @include setMinRwd{
                            margin-bottom: 1em;
                        }
                    }

                    &-body{
                        @include setMinRwd{
                            display: flex;
                            flex-direction: column;
                            padding-left: 3em;
                            padding-right: 3em;
                            .btn-wrap{
                                margin-top: auto;
                            }
                        }

                        @include setMaxRwd{
                            display: inline-flex;
                            flex-direction: column;
                            justify-content: space-between;
                        }
                    }

                    &-text{
                        min-height: 50px;
                        margin-bottom: 1.5em;
                        @include setMaxRwd{
                            height: 100%;
                        }
                    }

                    @include setMaxRwd{
                        flex: 0 0 100% !important;
                        max-width: 100% !important;
                        margin-left: 0;
                        margin-right: 0;
                    }
                }

                @include setMinRwd{
                    margin-top: 2em;
                    margin-bottom: 2em;
                }
            }

            &Act{
                margin-top: 2em;
                .card{
                    @extend .boxShadow;

                    &-title,
                    &-time{
                        padding-left: 0.5em;
                        -webkit-text-stroke: 0;
                    }

                    &-time{
                        @include fontSize(14px);
                        color: #686868;
                        font-weight: 100;
                        margin-top: 10px;
                        margin-bottom: 5px;
                        .icon{
                            margin-right: 5px;
                        }
                    }

                    &-img-top{
                        @include radius(30px);
                        overflow: hidden;
                    }

                    &-title{
                        @include fontSize(20px);
                        color: #3f403d;
                        padding-left: 0.75em;
                        margin-top: 0.75em;
                        margin-bottom: 1em;

                        @include setMaxRwd{
                            margin-bottom: 0;
                            &::before {
                                left: -7.5px;
                            }
                        }
                    }

                    &-body {
                        @include setMinRwd{
                            padding-left: 2em;
                            padding-right: 2em;
                        }
                    }

                    @include setMaxRwd{
                        flex: 0 0 100% !important;
                        max-width: 100% !important;
                        margin-left: 0;
                        margin-right: 0;
                    }
                }

                .btn{
                    padding-left: 0;
                    @extend .fw-900;
                }
            }
        }

        // 內頁
        &-main{
            &News,
            &Act{
                .card{
                    background-color: #f5f4f4;
                    box-shadow: none !important;
                    &:hover{
                        background-color: #fff;
                        @extend .boxShadow;
                    }
                }
            }

            &Link{
                .card{
                    &:hover{
                        .card-img-top{
                            background-color: #fff;
                            @extend .boxShadow;
                        }
                    }
                    
                    @include setMinRwd{
                        background-color: transparent;
                        box-shadow: none !important;
                        margin-bottom: 3em;
                        &:hover{
                            .card-title{
                                font-weight: 600;
                                text-decoration: underline;
                            }
                        }
                    }

                    @include setMaxRwd{
                        padding-top: 0.5em;
                        &:hover{
                            .card-img-top{
                                box-shadow: none !important;
                            }
                        }
                    }
                }

                .card-img-top{
                    text-align: center;
                    padding: 1.5em 1em 25%;
                    img{
                        width: auto;
                        @extend %imgCenter;
                    }

                    @include setMaxRwd{
                        padding-bottom: 42.5%;
                    }
                }

                .card-body{
                    @include setMaxRwd{
                        padding-top: 0;
                    }
                }

                .card-title{
                    font-weight: 100;
                    @include mobile{
                        @include fontSize(16px);
                        text-align: center;
                        margin-top: 0;
                    }
                }
            }

            &Act{
                .card{
                    &:hover{
                        border:$colorMain 1px solid;
                        box-shadow: none !important;
                    }
                }
            }
        }
    }

    &:hover{
        .card-btn{
            color: $card-btn-active;
            &::after{
                background-color:  $card-btn-active;
            }
        }
    }

    @include setMaxRwd{
        margin-bottom: 1em;
        
        &-title{
            @include fontSize(15px);
            margin-top: 0.25em;
        }

        &-body{
            padding: 0.75em; 
        }

        &-caption {
            padding-top: 0.125em;
            padding-bottom: 0.25em;
        }
    }
}

// 內頁
.news{
    .card{
        &-caption{
            padding-left: 0;
            padding-right: 0;
        }

        @include setMinRwd{
            margin-left: 1.5em;
            margin-right: 1.5em;
            flex: 0 0 calc(100% / 3 - (1.5em + 1.5em));
            max-width: calc(100% / 3 - (1.5em + 1.5em));
            &-img-top {
                margin-bottom: 1.5em;
            }
            
            &-btn {
                margin-top: 1.25em;
            }

            // 水平狀態
            &__horizontal{
                background: #14aa6b; /* Old browsers */
                background: -moz-linear-gradient(65deg,  #14aa6b 1%, #14aa6b 30%, #14aa6b 27%, #f5f5f5 27%, #f5f5f5 27%, #f5f5f5 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(65deg,  #14aa6b 1%,#14aa6b 30%,#14aa6b 27%,#f5f5f5 27%,#f5f5f5 27%,#f5f5f5 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(65deg,  #14aa6b 1%,#14aa6b 30%,#14aa6b 27%,#f5f5f5 27%,#f5f5f5 27%,#f5f5f5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#14aa6b', endColorstr='#f5f5f5',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

                padding: 25px;
                margin-bottom: 50px;
                .card-img-top {
                    flex: none;
                    width: 510px;
                    margin-right: 50px;
                    margin-bottom: 0;
                }
                
                .card-caption{
                    padding-left: 0;
                }

                .card-body {
                    display: flex;
                    flex-direction: column;
                    .card-btn{
                        margin-top: auto;
                    }
                }
            }
        }
    }

    &-content{
        .card{
            max-width: 100%;
            margin-bottom: 10px;
        }
    }

    // 相關連結
    &-link{
        border-top: 2px solid #000;
        border-bottom: 1px solid #666;
        .box{
            background-color: #f4f4f4;
            padding: 15px;
            & ~ .box{
                border-top: 1px solid #666; 
            }

            &-text{
                .icon{
                    margin-top: -9px;
                    margin-left: 3px;
                }
            }

            @include setMinRwd{
                &-head{
                    position: relative;
                    &::after {
                        content: "";
                        @include setSize(1px,17px);
                        background: #9a9999;
                        position: absolute;
                        top: 50%;
                        right: -17px;
                        transform: translate(50%, -50%);
                    }
                }

                &-text{ 
                    padding-left: 40px;
                }
            }

            @include setMaxRwd{
                flex-direction: column;

                &-head{
                    padding-left: 0;
                }

                &-text{
                    width: 100%;
                    padding-top: 5px;
                    word-break: break-all;
                }
            }
        }
    }

    &-article{
        margin-top: 3em;
        padding: 0.5em;
        @include setMinRwd{
            padding: 3em;
        }
    }
}

// 輪播用
.activity_Swiper{
    padding: 0 4em;
      .swiper-button{
        &-next,
        &-prev {
          color: #000;
          @include setSize(50px,50px);
          @include radius(50%);
          border: #d0d0d0 1px solid;
          &::after{
              font-size: 1.5rem;
          }
        }
        
        &-next{
            right: 0;
        }

        &-prev{
            left: 0;
        }
      }

      .swiper-slide-img{
        display: block;
        width: 100%;
        height: 0;
        padding-bottom: 65%;
        img{
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            max-height: 100%;
            margin: auto;
        }
      }
}